import React from 'react'
import { useState} from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import axios from 'axios';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pincodeRegex = /^[1-9][0-9]{5}$/
const validationSchema1 = Yup.object({
   
    
    pickup_name: Yup.string().required().min(3).max(200).label("Name"),
    pickup_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    pickup_address: Yup.string().required().min(10).max(200).label("Address"),
    pickup_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    pickup_city: Yup.string().required().min(3).max(30).label('city'),
    pickup_state: Yup.string().required().min(3).max(30).label('state'),
    
  
})


const EditWareHouseModal = ({isVisible,onClose,pickupData}) => {
    const navigate = useNavigate()
    // const [data,setData] = useState({
       
    //     pickup_name: pickupData ? pickupData.pickup_name : '',
    //     pickup_phone: pickupData ? pickupData.pickup_phone : '',
    //     pickup_address: pickupData ? pickupData.pickup_address : '',
    //     pickup_pincode:pickupData ? pickupData.pickup_pincode : '',
    //     pickup_city: pickupData ? pickupData.pickup_city : '',
    //     pickup_state: pickupData ? pickupData.pickup_state : '',
       
    // })
    const [agree, setAgree] = useState(false);
    const [error,setError] = useState(false)
    if(!isVisible) return null

    const handleSubmit = async (values)=>{
        console.log(values)
        var config = {
            method: 'patch',
            url: '/api/v1/warehouse/updatewarehouse',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values,
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                // let warehouse_id = response_data.data._id.toString()
                // console.log(warehouse_id)
                setAgree(false)
                //location.reload();
                navigate(0)
            }
        } catch (error) {
            setError(true)
            setTimeout(() => {
              navigate(0);
            }, 3000);
            //console.log("error message",error.response)
        }

    }
  return (
    <Main>
       <div id="myModal" className="modal">



            <div className="modal-content">
                {error && <h3>Something Wetn wrong please try again later</h3>}
                <button className="close" onClick={()=>onClose()}>&times;</button>
            
            <Formik 
                validationSchema={validationSchema1}
                initialValues= {pickupData}
            
                onSubmit={handleSubmit} 
            >
            {()=>(

                <> 
                    <Form className='formcontainer'>

                    <h3>Edit WareHouse Details</h3>
                        <div className='formrow'>
                            <label>WareHouse Name</label>
                            <Field className = 'inputbox greyedout' name = "pickup_name" placeholder= 'Enter Full Name' readOnly ></Field>
                            <ErrorMessage name = 'pickup_name'></ErrorMessage>
                            <label>Phone Number</label>
                            <Field className = 'inputbox' name = "pickup_phone" placeholder='Enter phone number' ></Field>
                            <ErrorMessage name = 'pickup_phone'></ErrorMessage>
                        </div>
                        <div className='formrow'>
                            <label>Address</label>
                            <Field className = 'inputbox' name = "pickup_address" placeholder= 'Enter Full Address' ></Field>
                            <ErrorMessage name = 'pickup_address'></ErrorMessage>
                            
                        </div>
                        <div className='formrow'>
                            <label>PinCode</label>
                            <Field className = 'inputbox' name = "pickup_pincode" placeholder= 'Enter PinCode' ></Field>
                            <ErrorMessage name = 'pickup_pincode'></ErrorMessage>
                            <label>City</label>
                            <Field className = 'inputbox' name = "pickup_city" placeholder='Enter City' ></Field>
                            <ErrorMessage name = 'pickup_city'></ErrorMessage>
                            <label>State</label>
                            <Field className = 'inputbox' name = "pickup_state" placeholder='Enter State' ></Field>
                            <ErrorMessage name = 'pickup_state'></ErrorMessage>
                        </div>
                        
                        <div className='buttonrow'>
                            <button className = 'buttonnext' type="submit" >Submit</button>
                    </div> 
                    </Form>
                </>
            )}
            </Formik>
            </div>
            
            
            
            
          
       


        </div>
    </Main>
  )
}
const Main = styled.div`
.modal {
     /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    border-radius: 8px;
  }
  
  /* The Close Button */
  .close {
    color: #F65282;
    float: right;
    font-size: 28px;
    font-weight: bold;
    border: 0;
    background: transparent;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .data{
    display:flex;
    flex-direction: column;
    background-color: #f2f8ff;
    border-radius: 8px;
    
  }
  .details{
    display:flex;
    flex-direction: column;
    background-color: #f6f6f6;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 1rem;
  }
  .detailrow{
    margin-bottom: 0.25rem;
    display:flex;
    justify-content: space-between;
    width: 50%;
    padding:10px;
    
  }
  .hr{
    color: #e3e3e3;
    height: 1px;
    margin: 0.5rem;
  }
  .strong{
    font-weight: bolder;
  }
  .button{
    background-color: #745be7   ;
    color: #fff;
    width: 200px;
    border-radius: 6px;
    font-family: "SFProDisplay-Bold";
    font-weight: 900;
    padding: 0;
    cursor: pointer;
    height: 36px;
    box-sizing : content-box;
    border: solid 1px #927bfc!important;
    margin-top: 10px;
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.4;
  }
  .greyedout{
    background: #ECECEC !important;
   
  }
  .formcontainer{
    display:flex;
    flex-direction: column;
}
.formrow{
    margin-bottom: 20px;
    display:flex;
    align-items: center;
    text-align: center;
    @media(max-width:980px){
        display:flex;
        flex-direction:column;
        gap:10px;
    }
}
h3{
  line-height: 16px!important;
  font-weight: 900;
  
  margin-bottom: 1.5rem!important;
  font-family: SFProDisplay-Bold;
  @media(max-width:980px){
      line-height: 5px;
      display: block;
      font-size: 20px;

  }
          
}




`

export default EditWareHouseModal