import React from 'react'
import { useEffect } from 'react'
import { useLayoutEffect,useState} from 'react';
import { useLocation, useParams,useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import axios from 'axios';
import CourierDetail from '../../components/CourierDetail';

const CourierDetails = () => {
const {id} = useParams(true)
const [courier,setCourier] = useState(null)
const [loading,setLoading] = useState(null)
useEffect(()=>{
    const request = axios.get(`/api/v1/courier/ship/${id}`).then(response => {
        setLoading(true)
        setCourier(response.data);
        setLoading(false)
        
    })

},[])

const handleLabel =  ()=>{
    
    const labelData = {"courierName":courier.courierName,"tracking":courier.tracking}
    var config = {
        method: 'post',
        url: '/api/v1/courier/label/',
        headers: {
            'Content-Type': 'application/json',            
        },
        data: labelData
       
    }
     axios(config).then(response=>{
        window.open(response.data,'_blank')
     })
    
}
  return (
    <div>
        {loading && <h1>Loading....</h1>}
        {courier && 
        <Main>
        <h3 className='h13'>Courier Details</h3>
        <CourierDetail data ={courier} index = {0} />
          {/* <ButtomContainer>
          <button className = 'button' type='button' onClick={handleLabel}>Show Label</button>
          <button className = 'button' type='button' onClick={handleTrackInfo}>Tracking</button>
          <button className = 'button' type='button' onClick={handlePickup}>Pickup</button>
          </ButtomContainer> */}
            
              {/* <div>
              
              {trackLoading && <h1>Loading....</h1>}
              {trackingInfo && 
            <>
              {trackingInfo.wbns.map((item,index)=>{
                return (
                <>
                <Tracking>
                  <div>
                    <span>Status</span>
                    <a> {item.status}</a>
                  </div>
                  <div>
                      <span>Location</span>
                      <a>{item.location}</a>
                    </div>
                    <div>
                      <span>Manifested Date</span>
                      <a>{item.manifested_date}</a>
                    </div>
                    
                    <div>
                    <span>Scan remark</span>
                      <a>{item.scan_remark}</a>
                    </div>
                </Tracking>
                </>
                )

              })}
            </>}
           
          </div> */}
            
      </Main>
        }
    </div>
  )
}
const Main = styled.section`
.button{
  background-color: #745be7;
  color: #fff;
  width: 120px;
  border-radius: 6px;
  font-family: "SFProDisplay-Bold;";
  font-weight: 900;
  padding: 0;
  cursor: pointer;
  height: 30px;
  box-sizing : content-box;
  border: solid 1px #927bfc!important;

  button:hover {
    background-color: #3e8e41;
  }

  img{
    padding:10px;
    background: #f8f8f8;
    padding-top:20px;
    border-bottom: 1px solid #dbdbdb;
  }

  h3{
    text-shadow: 0 2px white, 0 3px #777;
  }

  .h13{
    linear-gradient(to bottom left, #553c9a, #ee4b2b);font-size: 70px;
    font-weight: 600;
    background-image: linear-gradient(to left, #553c9a, #b393d3);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    color:red;
  }
  
  
}
`
const ButtomContainer = styled.div`
display: flex;
justify-content: space-between;padding-bottom:20px;
`

const Tracking = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-end;
margin-top:20px;
background: #f8f8f8;
border-bottom: 1px solid #dbdbdb;
border-radius: 6px;
  div{
    display: flex;
    flex-direction: column
    
  }
`

export default CourierDetails