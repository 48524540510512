import React from 'react'
import { Formik, Form, Field, ErrorMessage} from 'formik';
import styled from 'styled-components'
import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pincodeRegex = /^[1-9][0-9]{5}$/
const stepOneValidationSchema = Yup.object({
    destination_name: Yup.string().required().label("Name"),
    destination_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    destination_address: Yup.string().required().min(10).max(200).label("Address"),
    destination_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    destination_city: Yup.string().required().min(3).max(30).label('city'),
    destination_state: Yup.string().required().min(3).max(30).label('state')
    

})
const StepOne = (props)=>{

    const handleSubmit = (values)=>{
        props.next(values)

    }

    return(
        <Main>
        <h3>Add Destination Details</h3>
        <Formik 
            validationSchema={stepOneValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
        >
            {()=>(
            <>
                <Form className='formcontainer'>
                <div className='formrow'>
                    <label>Full Name</label>
                    <Field className = 'inputbox' name = "destination_name" placeholder= 'Enter Full Name'></Field>
                    <ErrorMessage name = 'destination_name'></ErrorMessage>
                    <label>Phone Number</label>
                    <ErrorMessage name = 'destination_phone'></ErrorMessage>
                    <Field className = 'inputbox' name = "destination_phone" placeholder='Enter phone number'></Field>
                </div>
                <div className='formrow'>
                    <label>Address</label>
                    <Field className = 'inputbox' name = "destination_address" placeholder= 'House/Floor No. Building Name or Street,Locality   '></Field>
                    <ErrorMessage name = 'destination_address'></ErrorMessage>
                </div>
                <div className='formrow'>
                    <label>PinCode</label>
                    <Field className = 'inputbox' name = "destination_pincode" placeholder= 'Enter PinCode'></Field>
                    <ErrorMessage name = 'destination_pincode'></ErrorMessage>
                    <label>City</label>
                    <Field className = 'inputbox' name = "destination_city" placeholder='Enter City'></Field>
                    <ErrorMessage name = 'destination_city'></ErrorMessage>
                    <label>State</label>
                    <Field className = 'inputbox' name = "destination_state" placeholder='Enter State'></Field>
                    <ErrorMessage name = 'destination_state'></ErrorMessage>
                </div >
                <div className='buttonrow'>
                    <button className = 'buttonnext'>Next</button>
                </div>
                    
                    
                </Form>
                
            </>
            )}
        </Formik>
        </Main>
    )

}

export default StepOne

const Main = styled.div`

margin-top: 10px;
font-family: SFProDisplay-Bold;

    h3{
        line-height: 16px!important;
        font-weight: 900;
        
        margin-bottom: 1.5rem!important;
        font-family: SFProDisplay-Bold;
        @media(max-width:980px){
            line-height: 5px;
            display: block;
            font-size: 15px;

        }
                
    }
    label{
        color: #F65282;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .formcontainer{
        display:flex;
        flex-direction: column;
    }
    .formrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        @media(max-width:980px){
            display:flex;
            flex-direction:column;
        }
    }

    .inputbox{
    height: 36px;
    border-radius: 6px;
    background-color: #fff;
    background-color:transparent;
    color: #505050!important;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: solid 1px #d3d3d3;
    border:none;
        border-bottom: solid 1px #86b7fe;
    padding-left: 10px;
    
    }

    .field1{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
    }

    .buttonrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        
    }
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 60px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }

`