import React,{ useEffect,useState}from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext';
import CourierDetail from '../../components/CourierDetail';

const AllShipment = () => {
  const {isLoading,showAlert,displayAlert,logoutUser} = useAppContext()  
  const [shipData, setShipData] = useState(null)
  const [loading , setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState(null)
  const navigate = useNavigate()

  useEffect(()=>{
    setLoading(true)
    axios.get('/api/v1/courier/ship').then(response=>{
        setShipData(response.data.reverse())
        setLoading(false)
        
    }).catch(error=>{
        setErrorMessage("something went wrong")
    })
  },[])

  
  return (
    <>
    <ActionBar>
            <h3>All Shipments</h3>
           
            
        </ActionBar>
        {loading && <h1>Loading....</h1>}
        
        {shipData &&  shipData.map((courier,index)=>{
                return(
                    <CourierDetail data ={courier} index={index} />

                )
            })
      

        }
    </>
  )
}
const ActionBar = styled.div`
display: flex;
margin-bottom:5px;
justify-content: space-between;


 h3{
    font-size: 50px;
    
 }
 button{
    border-radius: 4px;
    background-color: #ebf7e8;
    width: 102px;
    height: 32px;
    color: #745be7;
    font-size: 13px;
    border: none;
    cursor:pointer;
    padding: 10px;
    margin: 10px;
    color: #008e27;
 }

`
export default AllShipment