import React from 'react'
import { useState, useEffect } from 'react'
import { Logo,FormRow,Alert} from '../components'
import Wrapper from '../assets/wrappers/RegisterPage'
import { useAppContext } from '../context/appContext'
import { DISPLAY_ALERT } from '../context/action'
import {Link, useNavigate} from 'react-router-dom'

const initialState = {
  name: '',
  email: '',
  password: '',
  isMember: true,
  showAlert: false
}

const Register = () => {
    const navigate = useNavigate()
    const [values,setValues] = useState(initialState)
    
    const {user,isLoading,showAlert,displayAlert,registerUser,loginUser} = useAppContext()
   
    
    const toggleMember = ()=>{
        setValues({...values,isMember:!values.isMember})
    }



    const onSubmit = (e) =>{
        e.preventDefault()
        const { name, email, password, isMember } = values
        if (!email || !password || (!isMember && !name)) {
            //console.log(showAlert)
            displayAlert()
            return
        }
        
        const curretUser = {name,email,password}
        if(isMember){
          loginUser(curretUser)
        }
        else{
          registerUser(curretUser)
        }
        //console.log(values)


    }

    useEffect(() => {
      if (user) {
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    }, [user, navigate]);

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <Wrapper className='full-page'>
      {/* { <Logo />} */}
        <form className='form' onSubmit={onSubmit}>
             {/* <Logo /> */}
             <h3>{values.isMember?'Login':'Register'}</h3>
             {showAlert && <Alert />}
             {!values.isMember && <FormRow type="text" name="name" value={values.name} handleChange={handleChange} />  }
             
             <FormRow type="email" name="email" value={values.email} handleChange={handleChange} /> 
             <FormRow type="password" name="password" value={values.password} handleChange={handleChange} />           
             <button type='submit' className='btn btn-block' disabled={isLoading}>submit</button>
             <p>
                {values.isMember?'Not a member yet?':'Already a member?'}
                <button type="button" onClick={toggleMember} className='member-btn'>{values.isMember?'Register':'Login'}</button>
                
             
             </p>
             <p>
             <Link to='/forgot-password'  className='member-btn'>{values.isMember?'Forgot Password':''}</Link>
             </p>
             
             
             
            
        </form>
        
        
       
    </Wrapper>
  )
}

export default Register