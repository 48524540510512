import React from 'react'
import Wrapper from '../assets/wrappers/SmallSidebar'
import links from '../utils/links';
import { FaTimes } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Logo from './logo';
import { useAppContext } from '../context/appContext';
import NavLinks from './NavLinks';

const SmallSideBar = () => {
  const { showSidebar, toggleSidebar } = useAppContext();
  return (
    <Wrapper>
      <div className={showSidebar ? 'sidebar-container show-sidebar' : 'sidebar-container'}>
        <div className='content'>
          <button className='close-btn' onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <header>
            
          </header>
          <div className='nav-links'>
            {/* {links.map((link)=>{
              const {text,path,id,icon} = link */}
              {/* return( */}
                <NavLinks toggleSidebar ={toggleSidebar} />
              {/* ) */}
            {/* })} */}
          </div>
        </div>
      </div>
    </Wrapper>
    
  )
}

export default SmallSideBar