import React, { useState, useReducer, useContext } from 'react'
import reducer from './reducer'
import { DISPLAY_ALERT,CLEAR_ALERT,
  REGISTER_USER_BEGIN,REGISTER_USER_SUCCESS,REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,LOGIN_USER_SUCCESS,LOGIN_USER_ERROR,TOGGLE_SIDEBAR,LOGOUT_USER,
  UPDATE_USER_BEGIN,UPDATE_USER_SUCCESS,UPDATE_USER_ERROR
  } from './action'
import axios from 'axios'

const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
const userLocation = localStorage.getItem('location')


export const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: user ? JSON.parse(user):null,
  token:null,
  userLocation:userLocation || '',
  jobLocation: userLocation || '',
  showSidebar: false,

}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer,initialState)
    const displayAlert = ()=>{
        dispatch({type:DISPLAY_ALERT})
        clearAlert()
    }

    const clearAlert =()=>{
        setTimeout(()=>{
            dispatch({type:CLEAR_ALERT})

        },3000)
    }

    const addUserToLocalStorage = ({ user, token, location }) => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      localStorage.setItem('location', location);
    };

    const removeUserFromLocalStorage = async() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('location');
      localStorage.removeItem('transaction');
      localStorage.removeItem('shipmentdetails');
      try {
        const response = await axios.get('/api/v1/auth/logout')
      } catch (error) {
        console.log(error.response)
        
      }
    };
    

    const registerUser = async(curretUser)=>{
      //console.log(curretUser)
      dispatch({type:REGISTER_USER_BEGIN})
      try {
        const response = await axios.post('/api/v1/auth/register',curretUser)
        console.log(response)
        const {user,token,location} = response.data
        dispatch({type:REGISTER_USER_SUCCESS,payload:{user,token,location}})
        addUserToLocalStorage({user,token,location})
        
      } catch (error) {
        console.log(error.response)
        dispatch({type:REGISTER_USER_ERROR,payload:{msg:error.response.data.msg}})
        
        
      }
      clearAlert()
    }

    const loginUser = async (curretUser)=>{
      //console.log(curretUser)
      dispatch({type:LOGIN_USER_BEGIN})
      try {
        const response = await axios.post('/api/v1/auth/login',curretUser)
        //console.log(response)
        
        const {user,token,location} = response.data
        dispatch({type:LOGIN_USER_SUCCESS,payload:{user,token,location}})
        addUserToLocalStorage({user,token,location})
        clearAlert()

        
      } catch (error) {
        dispatch({type: LOGIN_USER_ERROR,payload: { msg: error.response.data.msg }});
        clearAlert()
        
      }

    }
    const toggleSidebar = () => {
      
      dispatch({ type: TOGGLE_SIDEBAR });
    };
    const logoutUser = ()=>{
      dispatch({type:LOGOUT_USER})
      removeUserFromLocalStorage()

    }
  const updateUser = async (curretUser)=>{
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const response = await axios.patch('/api/v1/auth/updateUser',curretUser,{
        headers:{Authorization:`Bearer ${state.token}`,},
      })
      const {user,location,token}=response.data
      //console.log(response)
      dispatch({type: UPDATE_USER_SUCCESS,payload: { user, location, token },
      });
      addUserToLocalStorage({ user, location, token });
      
    } catch (error) {
      if (error.response.status !== 401){
        dispatch({type: UPDATE_USER_ERROR,payload: {  msg: error.response.data.msg },})

      }
      

   }
   clearAlert()
 } 

    

  return (
    <AppContext.Provider value={{...state,displayAlert,registerUser,loginUser,toggleSidebar,logoutUser,updateUser}}>
      {children}
    </AppContext.Provider>
  )
}
export const useAppContext = () => {
  return useContext(AppContext)
}
export { AppProvider }
