import React from 'react'
import styled from 'styled-components'

export const TagLine = () => {
  return (
    <ContentContainer>
            <span>Prices Starting with as low as  </span>
            <span className='text-color'>Rs 19*</span>
            <span className='text'> | Limited Period Offer.| * T&C Apply </span>
            <a className='text' href='/register' target = '_blank' rel='noopener'>Get Free Quote</a>
            
        </ContentContainer>
  )
}

const ContentContainer =   styled.div`
background-color: #fed080;
border-color: #fed080;
padding-bottom:5px;
margin-bottom:5px;
font-size: 20px;
font-weight: 800;
height: 33px;
line-height: 33px;
position: relative;
text-align: center;
white-space: nowrap;
background-repeat: no-repeat;
border: 2px solid #FFF;
border-top-width: 0;
border-left-width: 0;
border-right-width: 0;
z-index: 1;
font: SF Pro Display;
word-spacing: 8px;
display: block;


.text-color{
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    
}
.text{
    display:none;
}
@media (min-width: 992px) {
    
    .text {
      display: inline;
    }
}
@media (max-width: 350px){
    width:100%;
    word-spacing:2px;
    span{
        word-wrap: break-word;
        
    }
}
@media (max-width: 250px){
    display:none;
}


`
