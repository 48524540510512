import React, { useEffect } from 'react'
import { useLayoutEffect,useState} from 'react';
import { useLocation, useParams,useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import axios from 'axios';
import Order from '../../components/Order.js';


const ShipOrder = () => {
    const {id} = useParams()
    const [order,setOrder] = useState(null);
    const [loading,setLoading] = useState(true)
    const [courierData,setCourierData] = useState('')
    const navigate = useNavigate()

    const handleCourierState = (data)=>{
        setCourierData(data)
        //console.log("ok",courierData)
    }
    let order1 = {
        "_id": "64d5b6cbbb7bcd1b952776f3",
        "owner": "6443a6fc92a50d0b81d723f7",
        "destination_name": "Vaibhav",
        "destination_phone": 9989864922,
        "destination_address": "GomtiNagar Lucknow",
        "destination_pincode": 226010,
        "destination_city": "lucknow",
        "destination_state": "uttar pradesh",
        "pickup_name": "srinika",
        "pickup_phone": 9989864922,
        "pickup_address": "Krishna nagar, mathura",
        "pickup_pincode": 281000,
        "pickup_city": "mathura",
        "pickup_state": "Uttar pradesh",
        "package_length": 10,
        "package_width": 10,
        "package_height": 23,
        "package_weight": 1,
        "payment_details": "prepaid",
        "product_name": "laptop",
        "product_quantity": 1,
        "product_price": 60000,
        "order_status": "New",
        "createdAt": "2023-08-11T04:19:23.286Z",
        "updatedAt": "2023-08-11T04:19:23.286Z",
        "__v": 0
    }
    useEffect( () => {
        if (!id) {
          return;
        }
        //console.log("ship order use effect")
        axios.get(`/api/v1/order/orderdata/${id}`).then(response => {
          setOrder(response.data);
          setLoading(false)
          
          
        });
      }, [id]);
      //console.log(order)
    
    const handleSubmit =(e)=>{
      //console.log(order.order_type)
     
        e.preventDefault();
        if (order.order_type === 'freight'){
          navigate('/order/freight/'+id)
        }else{
            navigate('/order/'+id)
        }
        
    }

    const onHandleDetails = ()=>{
      
      
      if(order.order_type === 'Courier'){
        navigate('/courier/'+order.shipment)
      }else{
        navigate('/shipment/'+order.shipment)
      }
      
    }
     
  return (
    <div>
        <H2>ShipOrder</H2>
         {loading && <h1>Loading....</h1>}
        {order && <Order data = {order} onDetail={()=>onHandleDetails()}/>}
        <ButtonEdit disabled = {order && order.order_status == 'New'?false:true} 
                    type='submit' onClick={handleSubmit}>Edit Order</ButtonEdit>
          
    </div>

  )
}

const H2 = styled.h2`
    color: #F65282;
    font-size: 40px;
    line-height: 30px;
    padding-bottom: 10px;
`
const ButtonEdit = styled.button`
background-color: #F65282;
color: #fff;
width: 80px;
border-radius: 6px;
font-family: "SFProDisplay-Bold";
font-weight: 900;
padding: 0;
cursor: pointer;
height: 30px;
box-sizing : content-box;
border: solid 1px #927bfc!important;

&:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  opacity: 0.4;
  }
`

export default ShipOrder