import React from 'react'
import { useState,useEffect } from 'react';
import { FormRow, Alert } from '../../components';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import axios from 'axios'
import CourierTable from '../../components/CourierTable';
import RateCalculator from '../../components/RateCalculator';

const AddJob = () => {
  const { user, showAlert, displayAlert, updateUser, isLoading } =useAppContext()

  const [pickUpPinCode,setPickUpPinCode]=useState('')
  const [deliveryPinCode,setDeliveryPinCode]=useState('')
  const [weight,setWeight] = useState('')
  const [height,setHeight] = useState('')
  const [length,setLength] = useState('')
  const [width,setWidth] = useState('')
  const [courierAPIData,setCourierAPIData]=useState([])
  const [flag,setFlag]=useState(false)
  const [loading,setLoading]=useState()

  const handleJobInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(`${name}:${value}`);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!pickUpPinCode || !deliveryPinCode || !weight || !height || !length || !width) {
      displayAlert();
      return;
    }
   
    //courierData()
    getPriceData()
   
    
    
  
    /* if(courierAPIData && courierAPIData.length >0){
      setFlag(true)
    } */
    
   
  };

  const getPriceData = async()=>{
    const courier_data = {
      "origin": pickUpPinCode,
      "destination": deliveryPinCode,
      //"payment_type": payment_details.toLowerCase(),
      "payment_type": "prepaid",
      "weight": parseInt(weight),
      "height": parseInt(height),
      "breadth": parseInt(width),
      "length": parseInt(length),
      //"order_amount": product_price
      "order_amount": 1000
    
  }
    var config = {
      method: 'post',
      url: '/api/v1/courier/price',
      headers: {
          'Content-Type': 'application/json',
          
      },
      data: courier_data
    }
    setLoading(true)
    try {
      const response = await axios(config)
      setFlag(false)
      setCourierAPIData(response.data)
      setLoading(false)
    } catch (error) {
      setFlag(true)
      setCourierAPIData([])
      setLoading(false)
    }
  }
    
  
  
  const courierData = async()=>{
   // const {pickUpPinCode,deliveryPinCode,weight} = courierAPIData
    //setFlag(false)
    const url = 'https://apiv2.shiprocket.in/v1/external/courier/serviceability/?pickup_postcode='+`${pickUpPinCode}`+'&delivery_postcode='+`${deliveryPinCode}`+'&cod=0&weight='+`${weight}`
    console.log(url)
    setLoading(true)
    try {
      const response =  await axios.get(url,{
        headers:{Authorization:'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjMxOTQyODQsImlzcyI6Imh0dHBzOi8vYXBpdjIuc2hpcHJvY2tldC5pbi92MS9leHRlcm5hbC9hdXRoL2xvZ2luIiwiaWF0IjoxNjg0MjEwNzE5LCJleHAiOjE2ODUwNzQ3MTksIm5iZiI6MTY4NDIxMDcxOSwianRpIjoiS3paNU1PM1BQVmxvUEJaWiJ9.l01m7lXWxJJ7Vzdq_D3D4X7uRkGj_-9SPI9vZEAWDzw',},
      })                               
      //return response
      setCourierAPIData([ response.data.data.available_courier_companies])
      
      
      //return [response.data.data.available_courier_companies]

    } catch (error) {
      console.log(error)
      
    }
    setLoading(false)
   

  }
  return (
      
      <Wrapper>
        <h3>Rate Calculator</h3>
        <form className='form'>
          
          {showAlert && <Alert />}
  
          {/* position */}
          <div className='form-center'>
            <FormRow
              type='text'
              name='Pickup Pin Code'
              value={pickUpPinCode}
              handleChange={(e)=>setPickUpPinCode(e.target.value)}
            />
            {/* company */}
            <FormRow
              type='text'
              name='Delivery Pin Code'
              value={deliveryPinCode}
              handleChange={(e)=>setDeliveryPinCode(e.target.value)}
            />
              <FormRow
              type='text'
              labelText='Weight in g'
              name='weight'
              value={weight}
              handleChange={(e)=>setWeight(e.target.value)}
            />
            {/* location */}
            <FormRow
              type='text'
              labelText='height in cm'
              name='heigth'
              value={height}
              handleChange={(e)=>setHeight(e.target.value)}
            />
             <FormRow
              type='text'
              labelText='length in cm'
              name='length'
              value={length}
              handleChange={(e)=>setLength(e.target.value)}
            />
             <FormRow
              type='text'
              labelText='Width in cm'
              name='width'
              value={width}
              handleChange={(e)=>setWidth(e.target.value)}
            />
           
            {/* job type */}
  
            {/* job status */}
  
            <div className='btn-container'>
                      <button className='btn btn-block submit-btn button' type='submit' onClick={handleSubmit} >
                        submit
                      </button>
                      {courierAPIData.length > 0 && 
                <>
                    <button className='btn btn-block submit-btn button'>Add Pickup</button>
                </>}
            </div>
          </div>
        </form>
        {loading && <h1>Loading....</h1>}
        <p></p>
        {/* {courierAPIData[0] && <CourierTable data={courierAPIData[0] }/>} */}
        {courierAPIData.length > 0 && 
         <>
            <div>
              <h2>Best Deals for Today</h2>
              
              
            </div>
            
            <RateCalculator data = {courierAPIData} />
        </>}
        
 
      </Wrapper>
  )
}

export default AddJob