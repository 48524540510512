import React, { useEffect } from 'react'
import { useLayoutEffect,useState} from 'react';
import styled from 'styled-components'
import axios from 'axios';
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext';

const AllJobs = () => {
  const {isLoading,showAlert,displayAlert,logoutUser} = useAppContext()  
  const [orderData, setOrderData] = useState(null)
  const [loading , setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState(null)
  const navigate = useNavigate() 

  const handleSubmit =(id)=>{
    //e.preventDefault();
    navigate('/shiporder/'+id)
  }
    const handleCreateOrder = ()=>{
        navigate('/order')
    }
  

  useEffect( () => {
    setLoading(true)
    axios.get('/api/v1/order/allorders').then(response => {
      setOrderData(response.data.reverse());
      setLoading(false)
      }).catch(error=>{
        if(error.response.status === 401){
            axios.get('/api/v1/auth/logout').then(response=>{
                console.log('logout')
                logoutUser()
            }).catch(error=>{
                navigate('/landing')
            })
        }else{
            setErrorMessage('Something went wrong, plese try again later')
        }
    });
  }, []);
  return (
    <div>
      {loading && <h1>Loading....</h1>}
      {errorMessage && <h1>{errorMessage}</h1>}
    <Main>
        <ActionBar>
            <h3>All Orders</h3>
            <button onClick={handleCreateOrder}>+ Add Courier</button>
            
        </ActionBar>
    <div className='tableresponsive'>
        <Table>
            <thead>
                <tr>
                    <th>Order Id</th>
                    <th>Package Details</th>
                    <th>Payment Option</th>
                    <th>Product</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>               
              </thead>
            {orderData && orderData.map((data)=>{
              return(
                <tr >
                        <td>
                            <div>
                                <a className='order'>{data._id}</a>
                                
                                <p>{new Date(data.createdAt).toDateString()}</p>
                                {data.orderNumber && <a>Ref Number:{data.orderNumber}</a>}
                                
                                
                                </div>
                            </td>
                            <td>
                                <div>
                                {data.order_type==='freight' 
                                    ? (<><p>Dead weight: {data.package_weight}kg</p>
                                          <p>Freight Order</p></>)
                                    : (
                                        <><p>Dead weight: {data.package_weight}g</p>
                                        <p>{data.package_length} x {data.package_width} x {data.package_height} cm</p>
                                        {data.fragile && <p>Fragile</p>}</>
                                    )}  
                                    
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{data.payment_details.toUpperCase()}</p>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{data.product_name}</p>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className={data.order_status === 'New'?'new':''} >{data.order_status.toUpperCase()}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <button className='buttonnext' type='submit' onClick={(e)=>handleSubmit(data._id)}>Details</button>
                                </div>
                            </td>
                        </tr> 
              )
            })} 
               
            </Table>
        </div>
                 
            
    </Main>
    </div>
  )
}
const Main = styled.section`
    
    
    background-color: #F2FFFA;
    background-color: #f8f8f8;
    background-color: #f7f3ff;
    flex-direction: row !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
    font-family: SFProDisplay-Regular,sans-serif;
    .tableresponsive{
        overflow-x:auto;
    }
    overflow-x:auto; 

    .buttonedit{
        background-color: #F65282;;
        color:  #212529;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }

   `
const ActionBar = styled.div`
display: flex;
margin-bottom:10px;
justify-content: space-between;
background-color: #fff;

 h3{
    font-size: 20px;
    margin: 10px;
 }
 button{
    border-radius: 4px;
    background-color: #ebf7e8;
    width: 102px;
    height: 32px;
    color: #745be7;
    font-size: 13px;
    border: none;
    cursor:pointer;
    padding: 10px;
    margin: 10px;
    color: #008e27;
 }

`
const Table = styled.table`
border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    font-family: SFProDisplay-Regular,sans-serif;
    
     thead{
        vertical-align: bottom;
        z-index: 1;
        position: sticky;
        top: 0;
        border: 0 solid;
        color: black
        border-radius: 8px;
    
    display: table-header-group;
    }
    tr{
        height: 3.834rem;
        vertical-align: middle;
        border-radius: 8px;
        box-shadow: 0 1px 1px rgba(0,0,0,.102);
        border: 0 solid;
        border-color: inherit;
        
    }
    th{
        background-color: #fff;
        color: black;
        font-family: "SFProDisplay-Medium";
        padding: 1rem;        
    }
    td{
        padding: 0;
    }
    tr:hover{
        background-color: unset ;
    }
  
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonedit{
        


        height: 15px;
        width:50px;
        font-size: 10px;
        background-color: #F65282;
        border-radius: 6px;
        cursor: pointer;
        border: solid 1px #927bfc!important;
        
    }
    .order{
        color: #745be7;
        padding-top:10px;
    }
    .new{
        border-radius: 8px;
        font-size: 20px;
        background-color: #ebf7e8;
        border: solid 1px #fff;
        width: 80px;
        height: 20px;
        color: #008e27;
        font-family: "SFProDisplay-Medium";
    }
`

export default AllJobs