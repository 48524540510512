import React,{useState} from 'react'
import { useLocation, useParams,useNavigate } from 'react-router-dom'
import { Logo,FormRow,Alert} from '../components'
import Wrapper from '../assets/wrappers/RegisterPage'
import { useAppContext } from '../context/appContext'
import { DISPLAY_ALERT } from '../context/action'
import {Link} from 'react-router-dom'
import axios from 'axios'


const ResetPassword = () => {
    const {token,id} = useParams(true)
    const navigate = useNavigate()
    const {user,isLoading,showAlert,displayAlert,registerUser,loginUser} = useAppContext()
    const [values,setValues] = useState('')
    const [error,setError] = useState('')
    const onSubmit = async(e)=>{
        e.preventDefault()
        const {password, password1} = values
        if(!password || !password1){
            displayAlert()
        }
        if(password !== password1){
            setError('passwords do not match')
            clearAlert()
        }
        try {
            const response = await axios.post('/api/v1/auth/reset-password',{token,id,password})
            console.log(response)
        } catch (error) {
            setError(error.response.data.msg)
            setTimeout(()=>{
                navigate('/register')
        
            },3000)
        }
        

    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        
  }
  const clearAlert =()=>{
    setTimeout(()=>{
        setError('')

    },3000)
}
  return (
    
    <div>
        <Logo />
    <form className='form' onSubmit={onSubmit}>
        
        {error && <p>{error}</p>}
              
        <FormRow type="password" name="password" value={values.password} handleChange={handleChange} />
        <FormRow type="password" name="Retype password" value={values.password1} handleChange={handleChange} /> 
                   
        <button type='submit' className='btn btn-block' disabled={isLoading}>submit</button>
                   
        
        
        
       
   </form>
</div>
  )
}

export default ResetPassword