import styled from 'styled-components'

const Wrapper = styled.main`

padding: 0;
margin:0;
border:0;
font-family: 'SF Pro Display';
// background: linear-gradient(175deg, rgba(39,171,172,1) 0%, rgba(173,171,10,1) 99%);
background-image: linear-gradient(to right, white , #745be7);
.logo{
    width: 250px;
    max-height: 145px;
    padding:0;
    margin:0;
    
}
.img_1{
  width:50px;
  height:50px;
}

.info{
  margin-top:-3rem;
  padding:5px;
  font-family: SFProDisplay-Bold;
  width:100%
  h1{
    margin-top:0;
    text-align: center;
    font-family: SFProDisplay-Bold;
  }
  h2{
    font-family: SFProDisplay-Bold;
    padding-left: 5px;
    margin-left:15px;
  }
}
.container {
  height: 100;
  width: 100%;
  max-width: 100%;
  
   
  position: relative;
  overflow: hidden;
}
  nav {
    width: 100%;
    max-width: 100%;
    margin: 0 ;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  nav ul {
    display: flex;
    list-style-type: none;  
}
nav ul li{
    
    margin:0 5px;
    
}
nav ul a{
    text-decoration: none;
    padding: 0.3rem 1.3rem;
    font-size: 17px;
    font-weight: bold;
    color: #494234;
    position: relative;
    z-index: 1;
}
nav ul a::after{
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    top:0;
    left:0px;
    border-radius: 20px;
    background-color: #C8EBB1;
    z-index: -1;
    transition: 0.5s;
}
nav ul a:hover:after{
    width: 100%;
}
  .page {
    min-height: calc(100vh - var(--nav-height));
    display: grid;
    align-items: center;
    margin-top: -3rem;
    
    margin:10px;
    
  }
  h1 {
    font-weight: 700;
    margin-bottom:10px;
    text-align: center;
    padding:0px;
    font-family: SFProDisplay-Bold;
    span {
      
    }
  }
  p {
    font-weight:500
    color: var(--grey-600);
    text-indent: 40px;
    margin-top: 0;
    font-family: SFProDisplay-Bold;
    
  }
  .main-img {
    display: none;
  }
  @media (min-width: 992px) {
    .page {
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
      font-family:SFProDisplay-Medium;
    }
    .main-img {
      display: block;
    }
  }
  .content{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;
      font-family:SFProDisplay-Medium;
      margin-bottom:20px;
    
    
  }
 
  .contenthover:hover{
    
    transform:scale(2) translate(25px);
    color:white;
    text-transform: uppercase;
    font-weight: 700;
    color: #f5f5f5;
    z-index: 1;
    transform-style: preserve-3d;
    text-shadow: 1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
    img{
      display:none;
    }
  }
  .inputcontainer{
    display:flex;
    flex-direction: column;
    margin-top:20px;
    width:70%;
    margin-left:70px;

    h1{
      padding:0;
      text-align:left;
    }
    
  }
 
  .inputbox{
    border: 1px solid #e0e3eb;
    width: 250px;
    height: 40px;
    color: grey;
    border-radius: 0.375rem;
    text-align: center;
    margin-top:10px;
    letter-spacing: 0px;
    color: #4C4C4C;
    opacity: 1;
    font-size: 14px;
    font-family:SFProDisplay-Medium;
    line-height: 16px;
    padding: 11px 17px;
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    width: 100%;
    
}
.inputsection{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #666;
  font-family: SFProDisplay-Medium;
  font: 400 16px/1.4 SF Pro Display;
  height:100px;
  font-family:SFProDisplay-Medium;
 
}
.inputlabel{
  letter-spacing: 0px;
  color: #4C4C4C;
  opacity: 1;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 500;
  font-family:SFProDisplay-Medium;
}
.button1{
    padding: 14px 61px;
    margin: 0px;
    letter-spacing: 0.36px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #00000000;
    cursor: pointer;
    width:250px;
    height:50px;
    background: #6f57e9;
    font-family:SFProDisplay-Medium;

}
.btn2{
  margin-right: 10px;
}

}  
@media (max-width: 650px){
  
  h2{
    margin-left:0;
    word-wrap: break-word;
    margin-top:0;

  }
  .info{
    margin-top:0;
  }
  .inputcontainer{
    width:90%;
  }
}
}
  
`
export default Wrapper