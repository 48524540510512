import React from 'react'
import { useState} from 'react'
import styled from 'styled-components'

const ViewCourierFormModal = ({isVisible,onClose,data}) => {
    const [agree, setAgree] = useState(false);
    if(!isVisible) return null
  return (
    <Main>
    <div id="myModal" className="modal">



         <div className="modal-content">
             
             <button className="close" onClick={()=>onClose()}>&times;</button>
             <h1>Order Details</h1>
             <div className='data details'>
                <div  className='detailrow'>
                    <span className='spanLable'>Order Id</span>
                <span>{data._id}</span>
                </div>
                <div  className='detailrow'>
                    <span className='spanLable'>Ref Order Number</span>
                <span>{data.orderNumber}</span>
                </div>
                
             </div>
             
             
             <div className='data details'>
                <h3>Customer Details</h3>
                <div className='detailrow'>
                    <span className='spanLable'>Customer name: </span><span>{data.destination_name}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Customer Address: </span><span>{data.destination_address}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Customer Phone: </span><span>{data.destination_phone}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Customer pincode: </span><span>{data.destination_pincode}</span>
                </div>
                
                
                
            </div>
            <div className='data details'>
            <h3>Pickup Details</h3>
                <div className='detailrow'>
                    <span className='spanLable'>Pickup Name: </span><span>{data.pickup_name}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Pickup Address: </span><span>{data.pickup_address}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Pickup Phone: </span><span>{data.pickup_phone}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Pickup pincode: </span><span>{data.pickup_pincode}</span>
                </div>
            </div>
            <div className='data details'>
                <h3>Package Details</h3>
                <div className='detailrow'>
                    <span className='spanLable'>Weight</span>
                    <span>{data.package_weight}g</span>                    
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Dimensions</span>
                    <span >{data.package_length}cm x {data.package_width}cm x {data.package_height}cm</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Payment</span>
                    <span>{data.payment_details}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Fragile</span>
                    {data.fragile?<span>Yes</span>:<span>No</span>}
                </div>
                
                
            </div>
            <div className='data details'>
                <h3>Product Details</h3>
                <div className='detailrow'>
                    <span className='spanLable'>Product Name</span>
                    <span>{data.product_name}g</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Product Quantity</span>
                    <span>{data.product_quantity}</span>
                </div>
                <div className='detailrow'>
                    <span className='spanLable'>Product Price</span>
                    <span>{data.product_price}</span>
                </div>
               
           
                
            </div>
            
        
         </div>
         
         
         
         
       
    


     </div>
 </Main>
)
}
const Main = styled.div`
.modal {
  /* Hidden by default */
 position: fixed; /* Stay in place */
 z-index: 1; /* Sit on top */
 left: 0;
 top: 0;
 width: 100%; /* Full width */
 height: 100%; /* Full height */
 overflow: auto; /* Enable scroll if needed */
 background-color: rgb(0,0,0); /* Fallback color */
 background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
 font-family: SFProDisplay-Regular;
}

/* Modal Content/Box */
.modal-content {
 background-color: #f2f8ff;
 margin: 15% auto; /* 15% from the top and centered */
 padding: 20px;
 border: 10px solid #888;
 width: 80%; /* Could be more or less, depending on screen size */
 border-radius: 20px;
 h3{
    color: #F65282;
    
   }
   span{
    
   }
 
}

/* The Close Button */
.close {
 color: #F65282;
 float: right;
 font-size: 28px;
 font-weight: bold;
 border: 0;
 background: transparent;
}

.close:hover,
.close:focus {
 color: black;
 text-decoration: none;
 cursor: pointer;
}
.data{
 display:flex;
 flex-direction: column;
 background-color: #f2f8ff;
 border-radius: 8px;
 
}
.details{
 display:flex;
 flex-direction: column;
 background-color: #f6f6f6;
 margin-bottom: 1rem;
 border-radius: 8px;
 padding: 1rem;
}
.detailrow{
 margin-bottom: 0.25rem;
 display:flex;
 justify-content: space-between;
 width: 50%;
 padding:10px;
 
}

.strong{
 font-weight: bolder;
}
.button{
 background-color: #745be7   ;
 color: #fff;
 width: 200px;
 border-radius: 6px;
 font-family: "SFProDisplay-Bold";
 font-weight: 900;
 padding: 0;
 cursor: pointer;
 height: 36px;
 box-sizing : content-box;
 border: solid 1px #927bfc!important;
 margin-top: 10px;
}
button:disabled {
 cursor: not-allowed;
 pointer-events: all !important;
 opacity: 0.4;
}
.greyedout{
 background: #ECECEC !important;

}
.spanLable{
    font-weight: 600;
    color: #885df1;
    
}



`

export default ViewCourierFormModal