import React, { useEffect,useState } from 'react'
import { useLocation,useNavigate  } from 'react-router-dom'
import axios from 'axios';
import { set } from 'mongoose';


const Billing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    //console.log('location', location)
    const [loading,setLoading] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState(null)
    const [shipmentData,setShipmentData] = useState({})
    const [shipmentPage, setShipmentPage] = useState(null)
    

    const readyShipmentData = (shipmentDetails)=>{
     
        let {courierVendor,orderdata} = shipmentDetails
        if(orderdata.order_type === 'freight'){
          let shipmentData = {
            "orderdata": orderdata,
            "freightData": courierVendor
          }
          return shipmentData
        }else{
          let shipmentData = {}
          if (courierVendor.name.includes('Delhivery')){
            shipmentData = {
             "orderData": orderdata,
             "freightData": courierVendor
           }
          } else{
            shipmentData = {
              "order_number": orderdata._id,
              "unique_order_number":"yes", 
              "shipping_charges": courierVendor.total_charges*1.18,
              "discount": " ",
              "cod_charges": courierVendor.cod_charges,
              "payment_type": orderdata.payment_details.toLowerCase(),
              "order_amount": orderdata.product_price,
              "package_weight": orderdata.package_weight,
              "package_length": orderdata.package_length,
              "package_breadth": orderdata.package_width,
              "package_height": orderdata.package_height,
              "request_auto_pickup": "yes",
              "consignee":
                          {
                          "name": orderdata.destination_name,
                          "address": orderdata.destination_address,
                          "address_2": '',
                          "city": orderdata.destination_city,
                          "state": orderdata.destination_state,
                          "pincode": orderdata.destination_pincode,
                          "phone": orderdata.destination_phone,
                          },
              "pickup":
                          {
                           "warehouse_name": orderdata.pickup_name,
                           "name" : orderdata.pickup_name,
                           "address": orderdata.pickup_address,
                           "address_2": " ",
                           "city": orderdata.pickup_city,
                           "state": orderdata.pickup_state,
                           "pincode": orderdata.pickup_pincode,
                           "phone": orderdata.pickup_phone
                          },
              "order_items":
                          [ {
                            "name": orderdata.product_name,
                            "qty": orderdata.product_quantity,
                            "sku": " ",
                            "price": orderdata.product_price,
                            },],
              "courier_id" : courierVendor.id,
              "courier_name":courierVendor.name,
              "collectable_amount":courierVendor.cod_charges,
              "freightData":courierVendor,
              "orderData":orderdata
                                 
            }
           }

          return shipmentData
        }
       
        
       
    }

    useEffect (()=>{
      const shipmentDetails = JSON.parse(localStorage.getItem('shipmentdetails'))
      const paymentDetails = JSON.parse(localStorage.getItem('transaction'))
      let shipmentdata = readyShipmentData(shipmentDetails)
      
      if(shipmentdata){
        setShipmentData(shipmentdata)
      }
      // const paymentDetails = {"transactionId":1703423782566,"amount":45}
      // const shipmentDetails = {"courierVendor":{"id":0,"name":"Delhivery Surface","total_charges":38,"cod_charges":0,"chargeable_weight":300,"tat":"2 days"},"orderdata":{"_id":"65859cb64830fb99d03f4873","owner":"65333f1aee147ee56d2e1a6a","destination_name":"Vaibhav Bhardwaj","destination_phone":9989864922,"destination_address":"GomtiNagar Lucknow near fun mall","destination_pincode":226010,"destination_city":"Lucknow","destination_state":"Uttar Pradesh","pickup_name":"Test","pickup_phone":9989864921,"pickup_address":"Gomti Nagar, lucknow","pickup_pincode":226010,"pickup_city":"Lucknow","pickup_state":"Uttar Pradesh","package_length":10,"package_width":10,"package_height":10,"package_weight":300,"payment_details":"Prepaid","product_name":"laptop","product_quantity":1,"product_price":3000,"order_type":"Courier","order_status":"New","insurance":"owner","fragile":false,"orderNumber":"1abc234","boxes":[],"createdAt":"2023-12-22T14:27:02.116Z","updatedAt":"2023-12-22T14:46:42.914Z","__v":0}}
      // let shipmentdata = readyShipmentData(shipmentDetails)
      
      //  if(shipmentdata){
      //   setShipmentData(shipmentdata)
      //  }

       axios.post('/api/v1/payment/transaction/status',{data:paymentDetails})
      .then(response=>{
        //console.log(response)
        setLoading(true)
        if(response.data.data.responseCode === 'SUCCESS'){
               setPaymentStatus(response.data)
             }
        return response.data.data.responseCode
      }).then(responseCode=>{
        if(responseCode === 'SUCCESS'){
          console.log(shipmentdata.orderData)
          if(shipmentdata.orderData.order_type === 'freight'){
            var config = {
              method: 'post',
              url:'/api/v1/freight/ship',
              headers: {
                'Content-Type': 'application/json',            
                },
              data: shipmentdata
            }
            return axios(config)

          }else{
            var config = {
              method: 'post',
              url: '/api/v1/courier/ship/',
              headers: {
                  'Content-Type': 'application/json',            
              },
              data: shipmentdata
             
             }

            return axios(config)
          }
         
        }
        else
          return {"msg":"something went wrong"}
      }).then(response=>{
        console.log(response)
        setShipmentPage(response.data)
        setLoading(false)
         //console.log(response.data)
          return response.data
      })
    
      
      // const request = axios.post('/api/v1/payment/transaction/status').then(response=>{
      //   setLoading(true)
      //   //console.log(response.data)
      //   if(response.data.data.responseCode === 'SUCCESS'){
      //     setPaymentStatus(response.data)
      //   }
        
      //   setLoading(false)
      //   return response.data
        
      // }).then(_=>{
      //   setLoading(true)
      //   var config = {
      //     method: 'post',
      //     url:'/api/v1/freight/ship',
      //     headers: {
      //       'Content-Type': 'application/json',            
      //       },
      //     data: shipmentdata
      //   }        
      //   const response =  axios(config)
      //   console.log(response.data)
      //   setLoading(false)
        
      // })
      
      
     
    },[])
  return (
    <>
    {loading && <h1>Loading.....</h1>}
    {paymentStatus && <h1>Creating Order</h1>}
    {shipmentPage && navigate('/courier/'+shipmentPage._id)}
    
    </>
  )
}

export default Billing