import React from 'react'
import { useState, useEffect } from 'react'
import { Logo,FormRow,Alert} from '../components'
import Wrapper from '../assets/wrappers/RegisterPage'
import { useAppContext } from '../context/appContext'
import { DISPLAY_ALERT } from '../context/action'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'

const ForgotPassword = () => {
    const {user,isLoading,showAlert,displayAlert,registerUser,loginUser} = useAppContext()
    const [alert,setAlert] = useState('')
    const [error,setError] = useState('')
    const [values,setValues] = useState('')
    const onSubmit = async(e)=>{
        e.preventDefault()
        const {email} = values
        if (!email){
            displayAlert()
            return
        }
        
           console.log(email)
           axios.post('/api/v1/auth/forgot-password',{email}).then(response=>{
            setAlert("email sent, please check your mail")
            console.log(response.data)
           }).catch((error)=>{
            setError(error.response.data)
            console.log(error.response.data)
           })
            
      
        

    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
  }
  return (
    <div>
        { <Logo /> }
         <form className='form' onSubmit={onSubmit}>
             {/* <Logo /> */}
             
             {showAlert && <Alert />}
             {alert && <h3>email sent</h3>}
             {error && <h2>{error.msg}</h2>}
             
             
             <FormRow type="email" name="email" value={values.email} handleChange={handleChange} /> 
                        
             <button type='submit' className='btn btn-block' disabled={isLoading}>submit</button>
                        
             
             
             
            
        </form>
    </div>
  )
}

export default ForgotPassword