import React from 'react'
import styled from 'styled-components'
import {useState} from 'react'
import axios from 'axios'
import CourierTable from './CourierTable'
import ViewCourierFormModal from '../pages/order/ViewCourierFormModal'

const Order = ({data,onDetail}) => {
    const [value, setNewValue] = useState("");
    const [courierAPIData, setCourierAPIData] = useState([])
    const [flag,setFlag] = useState(false)
    const [loading,setLoading] = useState()
    const [showModal , setShowModal] = useState(false)
    const [orderViewData,setOrderViewData] = useState(null)
    
    const handleSubmit = async ()=>{
        //console.log(data)
        const box_freight = data.boxes.map(item => {
            return {
              box_count: item.box_count,
              length_cm: item['length'],
              width_cm:item.width,
              height_cm: item.height
            };
          });
          
        const courier_data = {
            "origin": data.pickup_pincode,
            "destination": data.destination_pincode,
            "payment_type": data.payment_details.toLowerCase(),
            "weight": parseInt(data.package_weight),
            "height": parseInt(data.package_height),
            "breadth": parseInt(data.package_width),
            "length": parseInt(data.package_length),
            "order_amount": data.product_price
        }

        const freight_data = {
            // "dimensions":[
            //     {
            //         "length_cm":data.package_length,
            //         "width_cm": data.package_width,
            //         "height_cm": data.package_height,
            //         "box_count": 1
            //     }
            "dimensions":box_freight,
            "weight_g": parseInt(data.package_weight)*1000,
            "cheque_payment": false,
            "source_pin": data.pickup_pincode,
            "consignee_pin": data.destination_pincode,
            "payment_mode": data.payment_details.toLowerCase(),
            "inv_amount": data.product_price,
            "freight_mode": "fop",
            "rov_insurance": data.insurance==='owner'? false : true
        }
        if (data.order_type === 'Courier'){
            
            var config = {
                method: 'post',
                url: '/api/v1/courier/price',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                data: courier_data
    
            }
        }else{
            var config = {
                method: 'post',
                url: '/api/v1/freight/price',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                data: freight_data
    
            }

        }
       
        //console.log(freight_data)
        setLoading(true)
        try {
            const res_courier_data = await axios(config)
            console.log(res_courier_data.data)
            if(data.order_type === 'freight'){
                const freightShipData =[{ 
                    "name": "Delhivery",
                    //"total_charges": res_courier_data.data.total*1.1,
                    "total_charges": res_courier_data.data.price_breakup.pre_tax_freight_charges + 2.2*res_courier_data.data.charged_wt,
                    "chargeable_weight": res_courier_data.data.charged_wt*1000
                }]
                setCourierAPIData(freightShipData)
                setFlag(false)
            }else if(res_courier_data.status === 200 && res_courier_data.data.length > 0){
                
                setCourierAPIData(res_courier_data.data)
                setFlag(false)
            }else{
                setFlag(true)
                setCourierAPIData([])
            }
        } catch (error) {
            setFlag(true)
            setCourierAPIData([])
            
        }
        setLoading(false)
    }
    
  
    const getCourierData = async()=>{

    }
    const handleOrderDetails=(data)=>{
        console.log(data)
        setShowModal(true)
        setOrderViewData(data)

    }
  return (
    <Main>
        <Table>
            <thead>
                <tr>
                    <th>Order Id</th>
                    <th>Package Details</th>
                    <th>Payment Option</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                
                <tr >
                        <td>
                            <div>
                                <a className='order cursor' onClick = {()=>handleOrderDetails(data)}>{data._id}</a>
                                <p>{new Date(data.createdAt).toDateString()}</p>
                                {data.orderNumber && <a>Ref Number:{data.orderNumber}</a>}
                                
                                </div>
                            </td>
                            <td>
                                <div>
                                {data.order_type==='freight' 
                                    ? (<><p>Dead weight: {data.package_weight}kg</p>
                                          <p>Freight Order</p>
                                          {data.fragile && <p>Fragile</p>}</>)
                                    : (
                                        <><p>Dead weight: {data.package_weight}g</p>
                                        <p>{data.package_length} x {data.package_width} x {data.package_height} cm</p>
                                        {data.fragile && <p>Fragile</p>}
                                        </>
                                        
                                    )}    
                                    
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{data.payment_details.toUpperCase()}</p>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className={data.order_status === 'New'?'new':''} >{data.order_status.toUpperCase()}</p>
                                    
                                        {data.order_status === 'Shipment Created' && <p className={data.order_status === 'New'?'new':''} >
                                            </p>}
                                </div>
                            </td>
                            <td>
                                <div>
                                {data.order_status === 'Shipment Created' ? (<button className='buttonnext' type='submit' onClick={()=>onDetail()}>Details</button>) 
                                :(<button className='buttonnext' type='submit' onClick={handleSubmit}>Ship Now</button>)}   
                                </div>
                            </td>
                        </tr> 
                        </thead>
               
            </Table>
            {loading && <h1>Loading</h1>}
            <p></p>
            {flag && <h1>No courier available for pincodes</h1>}
            <ViewCourierFormModal isVisible={showModal} onClose={()=>setShowModal(false)} data = {orderViewData}></ViewCourierFormModal>
            {courierAPIData.length > 0 &&  <><h2>Best Deals for Today</h2><CourierTable data = {courierAPIData} orderid={data._id} orderdata={data}/></>}
            
            
    </Main>
  )
}
const Main = styled.section`
    
    
    background-color: #F2FFFA;
    background-color: #f8f8f8;
    background-color: #f7f3ff;
    flex-direction: row !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
    font-family: SFProDisplay-Regular,sans-serif;
    overflow-x:auto; 

    .buttonedit{
        background-color: #F65282;;
        color:  #212529;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }

   `
const Table = styled.table`
border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    font-family: SFProDisplay-Regular;
    
     thead{
        vertical-align: bottom;
        z-index: 1;
        position: sticky;
        top: 0;
        border: 0 solid;
        color: black
        border-radius: 8px;
    
    display: table-header-group;
    }
    tr{
        height: 3.834rem;
        vertical-align: middle;
        border-radius: 8px;
        box-shadow: 0 1px 1px rgba(0,0,0,.102);
        border: 0 solid;
        border-color: inherit;
        
    }
    th{
        background-color: #fff;;
        color: black;
        font-family: "SFProDisplay-Medium";
        padding: 1rem;        
    }
    td{
        padding: 0;
    }
    tr:hover{
        background-color: unset ;
    }
  
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonedit{
        


        height: 15px;
        width:50px;
        font-size: 10px;
        background-color: #F65282;
        border-radius: 6px;
        cursor: pointer;
        border: solid 1px #927bfc!important;
        
    }
    .order{
        color: #745be7;
        padding-top:10px;
        cursor:pointer;
    }
    .new{
        border-radius: 8px;
        font-size: 20px;
        background-color: #ebf7e8;
        border: solid 1px #fff;
        width: 80px;
        height: 20px;
        color: #008e27;
        font-family: "SFProDisplay-Medium";
    }
   
`


export default Order