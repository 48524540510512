import React from 'react'
import styled  from 'styled-components'
import heroimage1 from '../../assets/images/vab_hero_image_for_courier.png'

const AboutUs = () => {
  return (
    <Container>
        <h1>About Us</h1>
        <div className='container page'>
            <div className='span1'>
                <span >We are India’s emerging Logistics Courier and Shipping Aggregator Company.</span>
                <br></br>
                
                
                <span > We have affiliation with India’s major courier and shipping giants like Delhivery, Expressbees and Shadowfax .</span> 
                <br></br>
               
                <span >We offer lowest rates in India with round the clock customer support team,dedicated account manager to cater every small needs of our customer. Our services expands throughout India covering 20000+ pincodes.</span>
                
                {/* <span>We are a team dedicated for excellence and best customer experience, our co founders have range of business and marketing experience which helped them build a model of excellence like courierdeal in logistics industry. Courierdeal is build for best customer experience our technical team provides with best AIs to give customer best rates sorted on every pincode to choose for, whereas our operations team handles day to day shipment pickup and delhivery in a best professional manner, with our customer support team we assure best round the clock service to our customer</span> */}
            </div>
            <img src={heroimage1} className='main-img img'></img>
            
            
        
        </div>
        

    </Container>
  )
}

const Container = styled.div`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
  background: transparent linear-gradient(105deg, #FFC465 0%, #5338FF 100%);
  border-radius: 22px;
  overflow:hidden;
  max-width: 1320px;
  width: 90%;
  margin-right: 20px;;
  margin-left:20px;;
  display:flex;
  padding:10px;
  align-items: center;
  justify-content: space-between;
}

font-family: SFProDisplay-Medium;
border-bottom: 2px solid #FFF;

.page {
    min-height: calc(100vh - var(--nav-height));
    display: grid;
    align-items: center;
    margin-top: 1rem;
    
  }
  h1 {
    font-weight: 700;
   
    
  }
  span {
    color: black;
    font-size:24px;
    color: #666;
    
  }
  .span1{
    font-weight: 900;
    font-size: 30px;
    
    padding-top:40px;
    
  }
 
  .main-img {
    display: none;
  }
  @media (min-width: 992px) {
    
    .page {
      background: transparent;
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
      margin-top: -5rem;
      padding:10px;
      
    }
    .container{
      width:90%;
    }
    .main-img {
      display: block;
    }
    
  }

`

export default AboutUs