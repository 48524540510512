import React from 'react'
import styled from 'styled-components';
import { FaWhatsappSquare } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const FloatingWhatsapp = () => {
  return (
    <FAB>
        <div>

        
            <a href="https://wa.me/7991467997?text=Hi%20Courierdeal" className="floating" target="_blank">
                <FaWhatsapp className='fab-icon'></FaWhatsapp>
            </a>
            <i className="fab fa-whatsapp fab-icon"></i>
            
        </div>
        
        
    </FAB>
  )
}

const FAB = styled.div`
.floating {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    
   }
   
   .fab-icon {
    
    width: 80px;
    height: 80px;
    border-radius: 50px;
    background-color: #25d366;
   }
`

export default FloatingWhatsapp