import { IoBarChartSharp } from 'react-icons/io5';
import { MdQueryStats} from 'react-icons/md';
import { FaWpforms } from 'react-icons/fa';
import { ImProfile } from 'react-icons/im';
import {FaTruckMoving,FaTruckLoading,FaWarehouse,FaTruckPickup} from 'react-icons/fa'
import { GiCargoShip } from "react-icons/gi";

const links = [
  // {
  //   id: 1,
  //   text: 'stats',
  //   path: '/',
  //   icon: <IoBarChartSharp />,
  // },
  {
    id: 2,
    text: 'all orders',
    path: 'all-orders',
    icon: <MdQueryStats />,
  },
  {
    id: 8,
    text: 'all shipments',
    path: 'ship',
    icon: <GiCargoShip />,
  },
  {
    id: 3,
    text: 'Rate Calculator',
    path: 'add-job',
    icon: <FaWpforms />,
  },
  {
    id: 6,
    text: 'Add Pickup',
    path: 'warehouse',
    icon: <FaWarehouse />,
  },
  {
    id: 4,
    text: 'Courier',
    path: 'order',
    icon: <FaTruckPickup />,
  },
  {
    id: 5,
    text: 'Heavy Shipment',
    path: 'freightorder',
    icon: <FaTruckLoading />,
  },
 
  {
    id: 7,
    text: 'profile',
    path: 'profile',
    icon: <ImProfile />,
  },
 
];

export default links;