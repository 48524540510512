import React from 'react'
import styled from 'styled-components'
import Rnr from '../../assets/images/RNR.png'
import Kiyana from '../../assets/images/Kiyana.png'

const Testimonial = () => {
  return (
    <Container4>
            <div className='testimonialContainer'>            
                <div className='testimonialWrapper'>
                    {/* <FaTruckMoving/> */}
                    <img src={Rnr} alt="courier" className='img main-img'/>
                    <span>Rank Never Retires</span>
                    <span>One of the best courier services in India, with rates better than ShipRocket in almost all sectors and proactive support. My RTO is down by 90%. </span>
                    
                </div>
                <div className='testimonialWrapper'>
                <img src={Kiyana} alt="courier" className='testimonialimg '/>
                    {/* <FaTruckMoving/> */}
                    <span>Kiyana Candles</span>
                    <span>Dedicated account manager and proactive monitoring of courierdeal.com has made my life easy. Now I dont have to worry about logistics or shipment. </span>
                    
                </div>
                
                
            </div>
            
            
        </Container4>
  )
}
const Container4 = styled.div`
background: #fff
border-radius: 22px;
padding: 30px 50px 60px;
max-width: 1320px;
width: 100%;
margin-right: auto;
margin-left: auto;
border: 1px solid #f1f1f1;
font: 400 16px/1.4 SF Pro Display;
transition: all .4s ease-in .3s;
box-shadow: 0 10px 30px #38383812;

.mailtext{
    color: #5c5c5c;
    font-size: 14px;
}
.mailtext:hover{
    color:#735ae5;
}
.testimonialWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #fed080;
    height: 400px;
    width: 600px;
    margin: 10px;
    border-radius: 5px;
    font-size: 20px;
    padding: 15px;
    
    line-height: 1.5;
    font-family: SFProDisplay-Medium;
    
}
.testimonialContainer{
        display: flex;
        font-family: SFProDisplay-Medium;
    

}
.testimonialimg{
    width=100px; 
    height=100px;
}
@media (max-width: 900px) {
    padding:0;
    .testimonialWrapper{
        height:100%
    
    }
    img{
        display: none;
    }
    span{
        color:blue;
        height:100%;
        word-wrap: break-word;
        
        br {
            display: none;
        }
    }
}


`
export default Testimonial