import React ,{ useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import styled from 'styled-components'
import {useState} from 'react'
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext'
import AsyncSelect from 'react-select/async'
import { FcFullTrash } from 'react-icons/fc';
import * as Yup from 'yup'
import axios from 'axios';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pincodeRegex = /^[1-9][0-9]{5}$/
const validationSchema1 = Yup.object({
    destination_name: Yup.string().required().label("Name"),
    destination_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    destination_address: Yup.string().required().min(10).max(200).label("Address"),
    destination_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    destination_city: Yup.string().required().min(3).max(30).label('city'),
    destination_state: Yup.string().required().min(3).max(30).label('state'),
    //pickup_name: Yup.string().required().label("Name"),
    //pickup_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    //pickup_address: Yup.string().required().min(10).max(200).label("Address"),
    //pickup_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    //pickup_city: Yup.string().required().min(3).max(30).label('city'),
    //pickup_state: Yup.string().required().min(3).max(30).label('state'),
    //package_weight: Yup.number().typeError('Must be a number').required().label("Weight"),
    //package_length: Yup.number().typeError('Must be a number').required().label("Length"),
    //package_width: Yup.number().typeError('Must be a number').required().label("Breadth"),
    //package_height: Yup.number().typeError('Must be a number').required().label('Height'),
    payment_details: Yup.string().required().label('Payment Option'),
    product_name: Yup.string().required().min(3).max(30).label('Product'),
    product_quantity: Yup.number().typeError('Must be a number').required().label('Quantity'),
    product_price: Yup.number().typeError('Must be a number').required().label('Total Price'),
    product_invoice: Yup.string().required().min(2).label("Invoice") ,
    insurance:Yup.string().required().label('Insurance Type'),
    boxs: Yup.array().of(Yup.object().shape({
        box_count:Yup.number().required().label('Number of boxes'),
        length: Yup.number().required().label('Length'),
        width: Yup.number().required().label('Width'),
        height: Yup.number().required().label('Height')
    }))
})

const FreightForm = () => {
    const { user, showAlert, displayAlert, updateUser, isLoading } =useAppContext()
    const [name, setName] = useState(user?.name);
    const [location, setLocation] = useState(user?.location);
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState('');
    const [wareHouseErrorMessage, setWareHouseErrorMessage] = useState('');
    const [inputValue, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState({});
    const [data,setData] = useState({
        destination_name :'',
        destination_phone: '',
        destination_address: '',
        destination_pincode: '',
        destination_city: '',
        destination_state: '',
        pickup_name: '',
        pickup_phone: '',
        pickup_address: '',
        pickup_pincode:'',
        pickup_city: '',
        pickup_state: '',
        package_weight: '',
        package_length: '',
        package_width: '',
        package_height: '',
        payment_details: 'Prepaid',
        product_name:  '',
        product_quantity: '',
        product_price: '',
        package_number: '',
        product_invoice:'',
        insurance:'owner',
        boxs:[{box_count:'',length:'',width:'',height:''}]
    })
    const getWareHouseData = async()=>{
        var config = {
            method: 'get',
            url: '/api/v1/warehouse/',
            headers: {
                'Content-Type': 'application/json'
            },
            
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                //let warehouse_id = response_data.data._id.toString()
                console.log(response_data.data)
                return response_data.data
                //navigate('/shiporder/'+order_id)
            }
        } catch (error) {
            console.log("error message",error.response)
        }
    }

    const handleInputChange = values => {
        //setValue(value);
        console.log("input",values)
      };
    
      // handle selection
      const handleChange = values => {
        //setSelectedValue(value);
        setSelectedValue(values)
        setWareHouseErrorMessage('')
        
      }
    const handleSubmit = async(values)=>{
       const formData = {...values,order_type:'freight',pickup_name:selectedValue.pickup_name,
        pickup_address:selectedValue.pickup_address,
        pickup_phone:selectedValue.pickup_phone,pickup_pincode:selectedValue.pickup_pincode,
        pickup_city:selectedValue.pickup_city,pickup_state:selectedValue.pickup_state
        }
        if(!formData.pickup_name){
            console.log(formData.pickup_name)
            setWareHouseErrorMessage('please select warehouse')
            return
        }
        //console.log(formData)
        var config = {
            method: 'post',
            url: '/api/v1/order/createorder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData,
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                let order_id = response_data.data._id.toString()
                //console.log(response_data.data)
                navigate('/shiporder/'+order_id, )
            }
        } catch (error) {
            console.log("error message",error.response)
            setErrorMessage('Something went Wrong please try again later');
        }
        

    }
    const handleCancel = ()=>{
        navigate('/freightorder')
    }
    const handleSubmit1=(values)=>{
        if (!selectedValue){
            console.log(selectedValue)
            setWareHouseErrorMessage('please select warehouse')
            return
        }
        const formData = {...values,order_type:'freight',pickup_name:selectedValue.pickup_name,
    pickup_address:selectedValue.pickup_address,
    pickup_phone:selectedValue.pickup_phone,pickup_pincode:selectedValue.pickup_pincode,
    pickup_city:selectedValue.pickup_city,pickup_state:selectedValue.pickup_state
    }
    if(!formData.pickup_name){
        //console.log(formData.pickup_name)
        setWareHouseErrorMessage('please select warehouse')
        return
    }
        //console.log(formData)

    }
    return (
        <>
         <H3><span> Create Heavy Shipment </span> <label>For Weights greater than 20Kg</label></H3>
         
         <Main>
            
            <h3>Add Destination Details</h3>
            <Formik 
                validationSchema={validationSchema1}
                initialValues= {data}
              
                onSubmit={handleSubmit}        
            >
                {(values)=>(
                <>
                    <Form className='formcontainer'>
                    <div className='formrow'>
                        <label>Full Name</label>
                        <Field className = 'inputbox' name = "destination_name" placeholder= 'Enter Full Name' ></Field>
                        <ErrorMessage name = 'destination_name'></ErrorMessage>
                        <label>Phone Number</label>
                        <Field className = 'inputbox' name = "destination_phone" placeholder='Enter phone number' ></Field>
                        <ErrorMessage name = 'destination_phone'></ErrorMessage>
                    </div>
                    <div className='formrow'>
                        <label>Address</label>
                        <Field className = 'inputbox' name = "destination_address" placeholder= 'House/Floor No. Building Name or Street,Locality '></Field>
                        <ErrorMessage name = 'destination_address'></ErrorMessage>
                    </div>
                    <div className='formrow'>
                        <label>PinCode</label>
                        <Field className = 'inputbox' name = "destination_pincode" placeholder= 'Enter PinCode' ></Field>
                        <ErrorMessage name = 'destination_pincode'></ErrorMessage>
                        <label>City</label>
                        <Field className = 'inputbox' name = "destination_city" placeholder='Enter City' ></Field>
                        <ErrorMessage name = 'destination_city'></ErrorMessage>
                        <label>State</label>
                        <Field className = 'inputbox' name = "destination_state" placeholder='Enter State' ></Field>
                        <ErrorMessage name = 'destination_state'></ErrorMessage>
                    </div >
                    <h3>Add PickUp Details</h3>
                    <div className='formrow'>
                    <label>WareHouse Name</label>
                    <AsyncSelect
                            cacheOptions
                            defaultOptions
                            value={values.pickup_name}
                            getOptionLabel={e => e.pickup_name}
                            getOptionValue={e => e.id}
                            loadOptions={getWareHouseData}
                            onInputChange={handleInputChange}
                            onChange={ handleChange}
                            noOptionsMessage={()=>"WareHouse not found"}
                            
                    />
                    {wareHouseErrorMessage && <p>Please select warehouse,if not available please create new warehouse</p>}
                    </div>
                    {/* <div className='formrow'>
                        <label>WareHouse Name</label>
                        <Field className = 'inputbox' name = "pickup_name" placeholder= 'Enter Full Name' ></Field>
                        <ErrorMessage name = 'pickup_name'></ErrorMessage>
                        <label>Phone Number</label>
                        <Field className = 'inputbox' name = "pickup_phone" placeholder='Enter phone number' ></Field>
                        <ErrorMessage name = 'pickup_phone'></ErrorMessage>
                    </div>
                    <div className='formrow'>
                        <label>Address</label>
                        <Field className = 'inputbox' name = "pickup_address" placeholder= 'Enter Full Address' ></Field>
                        <ErrorMessage name = 'pickup_address'></ErrorMessage>
                        
                    </div>
                    <div className='formrow'>
                        <label>PinCode</label>
                        <Field className = 'inputbox' name = "pickup_pincode" placeholder= 'Enter PinCode' ></Field>
                        <ErrorMessage name = 'pickup_pincode'></ErrorMessage>
                        <label>City</label>
                        <Field className = 'inputbox' name = "pickup_city" placeholder='Enter City' ></Field>
                        <ErrorMessage name = 'pickup_city'></ErrorMessage>
                        <label>State</label>
                        <Field className = 'inputbox' name = "pickup_state" placeholder='Enter State' ></Field>
                        <ErrorMessage name = 'pickup_state'></ErrorMessage>
                    </div> */}
                    
                    <h3>Add orders Details</h3>
                    <div className='formrow'>
                        <label>Dead Weight in Kg</label>
                        <Field className = 'inputbox' name = "package_weight" placeholder= 'Enter Weight in Kgs'></Field>
                        <ErrorMessage name = 'package_weight'></ErrorMessage>
                        
                    </div>
                    <div className='formrowbackground'>
                        <label className='product' >Dimensions in Centimeter</label>
                        
                    </div>
                    <label>List of boxes</label>
                    <FieldArray name='boxs' className='formrow'>
                        
                        {fieldArrayProps=>{
                            //console.log(fieldArrayProps)
                            const {push, remove, form} = fieldArrayProps
                            const {values} = form
                            const {boxs} = values
                            return (<div>
                                {
                                    boxs.map((box,index)=>{
                                        
                                return <div key={index} className='formrow'>
                                    <label>Number</label>  
                                      <Field className = 'inputbox box' name = {`boxs[${index}].box_count`} placeholder= 'Number of Boxes'></Field>
                                      <ErrorMessage name ={`boxs[${index}].box_count`}></ErrorMessage>
                                      <label>Length</label>  
                                      <Field className = 'inputbox box' name = {`boxs[${index}].length`} placeholder= 'Length in cm'></Field>
                                      <ErrorMessage name ={`boxs[${index}].length`}></ErrorMessage>
                                      <label>Width</label>
                                    <Field className = 'inputbox box' name = {`boxs[${index}].width`} placeholder='Width in cm'></Field>
                                    <ErrorMessage name ={`boxs[${index}].width`}></ErrorMessage>
                                    <label>Height</label>
                                    <Field className = 'inputbox box' name = {`boxs[${index}].height`} placeholder='Height in cm'></Field>
                                      
                                      {index >0 && (<button className='trashbox' type='button'onClick={()=>remove(index)}><FcFullTrash /></button>)}
                                    </div>
                                    
                                    })
                                }
                                <button className = 'buttonAdd' type='button' onClick={()=>push('')}>+Add Box</button>
                            </div>)
                        }
                        
                        }

                    </FieldArray>
                    {/* <div className='formrow'>
                        <label>Length</label>
                        <Field className = 'inputbox' name = "package_length" placeholder= 'Length in cm'></Field>
                        <ErrorMessage name = 'package_length'></ErrorMessage>
                        <label>Width</label>
                        <Field className = 'inputbox' name = "package_width" placeholder='Width in cm'></Field>
                        <ErrorMessage name = 'package_width'></ErrorMessage>
                        <label>Height</label>
                        <Field className = 'inputbox' name = "package_height" placeholder='Height in cm'></Field>
                        <ErrorMessage name = 'package_height'></ErrorMessage>
                    </div> */}
                    <div className='formrowbackground'>
                        <label className='product'>Payment Details</label>
                        
                    </div>
                    <div className='formrow'>
                        <label>Prepaid</label>
                        <Field type = 'radio' value = 'Prepaid' name = "payment_details" ></Field>
                        <label>COD</label>
                        <Field type = 'radio' value = 'Cod' name = "payment_details" ></Field>
                        <ErrorMessage name = 'payment_details'></ErrorMessage>
                        
                    </div>
                    <div>
                    <div className='formrowbackground'>
                        <label className='product'>Insurance Type</label>
                        
                    </div>
                    <div className='formrow'>
                        <label>Owners Risk</label>
                        <Field type = 'radio' value = 'owner' name = "insurance" ></Field>
                        <label>Carriers Risk</label>
                        <Field type = 'radio' value = 'carrier' name = "insurance" ></Field>
                        <ErrorMessage name = 'insurance'></ErrorMessage>
                        
                    </div>
                    <div className='formrowbackground'>
                        <label className='product'>Product Details</label>
                        
                    </div>
                    
                    <div className='formrow'>
                        <label>Name</label>
                        <Field className = 'inputbox' name = "product_name" placeholder= 'Name of product' ></Field>
                        <ErrorMessage name = 'product_name'></ErrorMessage>
                        <label>Quantity</label>
                        <Field className = 'inputbox' name = "product_quantity" placeholder='Quantity' ></Field>
                        <ErrorMessage name = 'product_quantity'></ErrorMessage>
                        <label>Price</label>
                        <Field className = 'inputbox' name = "product_price" placeholder='Total Price' ></Field>
                        <ErrorMessage name = 'product_price'></ErrorMessage>
                        <label>Invoice Number</label>
                        <Field className = 'inputbox' name = "product_invoice" placeholder= 'Invoice number' ></Field>
                        <ErrorMessage name = 'product_invoice'></ErrorMessage>
                    </div>
                    </div>
                    
                    <div className='buttonrow'>
                        <button className = 'buttonback' type = "button" onClick={handleCancel} >Cancel</button>
                        <button className = 'buttonnext' type="submit" >Submit</button>
                    </div>
                    
                    </Form>
                    {errorMessage && ( <h3 className="error"> {errorMessage} </h3>)}
                </>
                )}
            </Formik>
            
            </Main>
            </>
    
            
      )
    }
    const H3 = styled.h3`
    text-align: center;
    margin-bottom:30px;
    font-family: "SFProDisplay-Bold";
    font-weight: 900;
    color: #F65282;
   span{
        background-color: rgba(255, 234, 234, 0.57);
    }
    label{
        font-size: 14px;
        color: #555;
    }
    
   
    `
    const Main = styled.div`
    margin-top: 10px;
    font-family: SFProDisplay-Bold;
    
        h3{
            line-height: 16px!important;
            font-weight: 900;
            
            margin-bottom: 1.5rem!important;
            font-family: SFProDisplay-Bold;
                    
        }
        label{
            color: #F65282;
            margin-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
    
        .formcontainer{
            display:flex;
            flex-direction: column;
        }
        .formrow{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            text-align: center;
        }
    
        .inputbox{
        height: 36px;
        border-radius: 6px;
        background-color: #fff;
        color: #505050!important;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: solid 1px #d3d3d3;
        padding-left: 10px;
        }
    
        .field1{
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
        }
    
        .buttonrow{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
            
        }
        .buttonnext{
            background-color: #745be7;
            color: #fff;
            width: 60px;
            border-radius: 6px;
            font-family: "SFProDisplay-Bold;";
            font-weight: 900;
            padding: 0;
            cursor: pointer;
            height: 30px;
            box-sizing : content-box;
            border: solid 1px #927bfc!important;
            
        }
        .buttonback{
            background-color: #fff;
            color: #735ae5;
            width: 60px;
            border-radius: 6px;
            font-family: "SFProDisplay-Bold;";
            font-weight: 900;
            padding: 0;
            cursor: pointer;
            height: 30px;
            border: solid 1px #927bfc!important;
            text-align: center;
            box-sizing : content-box;
            margin-right: 1.33rem;
            
            
            
        }
        .formrowbackground{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            text-align: center;
            background-color: #e0fcff;
            
        }
        .product{
            color:black;
            font-size: 20px;
            margin-top: 10px;
        }
        .box{
            border: none;
            border-bottom: 1px solid #a6a6a6;
            border-radius: None;
            background: transparent;
        }
        .buttonAdd{
            border-radius: 4px;
            background-color: #f0503c;
            width: 102px;
            height: 32px;
            color: #fff;
            font-size: 14px;
            border: 1px solid #f0503c;
            cursor:pointer;
            margin: 10px;
           
        }
        .trashbox{
            background: transparent;
            border:none;
            cursor:pointer;
        }
    }
    
    `
    
export default FreightForm