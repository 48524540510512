import React from 'react'
import styled  from 'styled-components'
import Truck from '../assets/images/truck11.png'
import Box from '../assets/images/box.png'

const TruckAnimation = () => {
  return (
    <Container >
        <div className='wrap'>
            <img className='image truck' src={Truck}  />
            <img className='image box' src={Box}  />
        </div>
        

    </Container>
  )
}

const Container = styled.div` 

margin:0;
padding:0;
box-sizing:border-box;

.wrap{
    
    height:20px;
    top:20px;
    padding:15px;
    left:0;
    width:100%;
}
.image{
    width: 100px;
    position: relative;
}
.truck{
    bottom:0;
    left: -150px;
    z-index: 2;
    animation: 10s truck infinite linear;
}

@keyframes truck{
    40%,70%{
        left:40%;
    }
    100%{
        left: 100%;
    }

}

.box{
    width:35px;
    z-index: 1;
    top:55%;
    left: 40%;
    opacity: 0;
    animation: 10s box infinite linear;
}
@keyframes box {
    0%,40%{
        opacity: 0;
        left: 35%;
   }
   45%{
        opacity: 1;
        left: 30%
   }
   55%,100%{
    opacity: 0;
   }
}

`

export default TruckAnimation