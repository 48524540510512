
import styled from 'styled-components'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import {Dashboard,Landing,Error,Register,ProtectedRoute} from './pages/index'
import {AddJob,Profile,AllJobs,Stats,SharedLayout} from './pages/dashboard/index'
import Order from './pages/order/Order'
import ShipOrder from './pages/ship/ShipOrder'
import ShipmentDetails from './pages/ship/ShipmentDetails'
import CourierDetails from './pages/ship/CourierDetails'
import EditForm from './pages/order/EditForm'
import Billing from './pages/ship/Billing'
import FreightForm from './pages/order/FreightForm'
import TandC from './pages/TandC'
import Warehouse from './pages/warehouse/Warehouse'
import EditFreightForm from './pages/order/EditFreightForm'
import CreateWareHouse from './pages/warehouse/CreateWareHouse'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import AllShipment from './pages/ship/AllShipment'
function App() {
  return (
    <BrowserRouter>
  
    <Routes>
      <Route path="/" element={
      <ProtectedRoute>
        <SharedLayout />
      </ProtectedRoute>
        }>
      <Route index element={<AddJob />} />
      <Route path='all-orders' element={<AllJobs />}></Route>
      <Route path='add-job' element={<AddJob />}></Route>
      <Route path='profile' element={<Profile />}></Route>
      <Route path='order' element = {<Order />}></Route>
      <Route path='shiporder/:id' element = {<ShipOrder />}></Route>
      <Route path='order/:id' element = {<EditForm />}></Route>
      <Route path='order/freight/:id' element = {<EditFreightForm />}></Route>
      <Route path='shipment/:id' element = {<ShipmentDetails />}></Route>
      <Route path='courier/:id' element = {<CourierDetails />}></Route>
      <Route path='ship' element = {<AllShipment />}></Route>
      
      <Route path='billing' element = {<Billing />}></Route>
      <Route path='freightorder' element = {<FreightForm />}></Route>
      <Route path ='warehouse' element = {<Warehouse/>}> </Route>
      <Route path ='warehouse/create' element = {<CreateWareHouse/>}> </Route>
      

    </Route>
      <Route path="/register" element={<Register/>} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<Error/>} />
      <Route path='terms' element = {<TandC />}></Route>
      <Route path='/forgot-password' element = {<ForgotPassword/>}></Route>
      <Route path='/passwordReset/:token/:id' element = {<ResetPassword/>}></Route>
    </Routes>
</BrowserRouter>
    
  );
}

export default App;
