import React from 'react'
import styled from 'styled-components'
import Tdoc from '../assets/files/Courierdeal.com merchant Agreement.doc'

const TandC = () => {
  return (
    
    <Main>
       

<ul>
<h3 className='heading'> Terms and Conditions </h3>
       
<p><b>Account Terms</b></p>
  <li>
  You must be 18 years or older to use this Service
  </li>
  <li>You must provide your full legal name, current address, a valid email address, and any other information needed in order to complete the signup process.</li>
  <li>You are responsible for keeping your password secure. CourierDeal.com™ cannot and will not be liable for any loss or damage from your failure to maintain the security of your account and password.</li>
  <li>You may not use the CourierDeal.com™ service for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws) as well as the laws of India.</li>
  <li>You must not transmit any worms or viruses or any code of a destructive nature.</li>
  <li>A breach or violation of any of the Account Terms as determined in the sole discretion of CourierDeal.com™ will result in an immediate termination of your services.</li>
</ul>
<ul>
<p><b>Payment of Fees</b></p>
<li>There are different payment term options available, and depending on the payment term decided with CourierDeal.com and merchant, the merchant has to pay on the pre-decided monthly, quarterly, half-yearly or yearly terms.</li>
<li>The merchant has to pay with-in 7 days from the date of Invoice, or he/she risks the chance of closure/termination of the account.</li>

</ul>
<ul>
  <p><b>Shipping</b></p>
  <li>At the time of order creation, user need to provide accurate pickup and destination details.</li>
  <li>Any charges incurred due to wrong information shall be paid by the merchant.</li>
  <li>In case of any discrepancies due to dead weight/volumetric weight, decision of the courier/logistics partner will be binding on all parties</li>
  <li>CourierDeal.com will not be liable for any delay in Shipment</li>
  <li>For more information, please go through the merchant agreement</li>
</ul>
<ul>
  <p><b>Cancellation and Refund</b></p>
  <li>Cancellation of Shipment is allowed till 1 hour befor pickup.</li>
  <li>Cancellation after pickup, will lead to charges that will be deducted and balance amount will be trasferred to the merchant.</li>
  <li>Refund will be processed in 7 working days.</li>
  <li>In case of refund demand, decision of CourierDeal.com will be final and binding on all parties</li>
  <li>For more information, please go through the merchant agreement.</li>
</ul>

<p><b>Terms and conditions mentioned in the agreement are final and merchant by registering on the site agrees to bind by them.</b></p>
<button><a href={Tdoc} target="_blank" rel="noreferrer"> <b>Courierdeal.com Merchant Agreement</b></a></button>



            

    </Main>
  )
}
const Main = styled.div`
margin:30px;
width: 100%;
max-width: 1320px;
text-align: justify;
display:inline-block;
&:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.heading{
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 10px;
  font: normal normal bold 42px/50px SF Pro Display;
  letter-spacing: 0px;
  color: #0B0757;
  margin: 0px 0px 30px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
 ul {
  list-style: inside none disc;
  margin-bottom: 20px;
  padding-left: 24px;

li:before{
  content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #646464;
    border-radius: 50%;
    left: -24px;
    top: 8px;
    font: normal normal normal 16px/25px SF Pro Display;
    letter-spacing: 0.32px;
    color: #333333;
}
`
export default TandC