import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import Wrapper from '../../assets/wrappers/SharedLayout'
import {Logo,FormRow,Alert,BigSideBar,SmallSideBar,Navbar} from '../../components/index'


const SharedLayout = () => {
    
  return (
    <>
      <Wrapper>
        <main className='dashboard'>
          <SmallSideBar />
          <BigSideBar />
          <div>
            <Navbar />
            <div className='dashboard-page'>
              <Outlet />
            </div>
          </div>
        </main>
      </Wrapper>
    </>
    
  )
}

export default SharedLayout