import React from 'react'
import {useMemo, useState} from 'react'
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import {useTable,useSortBy} from 'react-table'
import '../assets/css/table.css'
import styled from 'styled-components'

const RateCalculator = ({data}) => {
    const navigate = useNavigate() 
    data.sort((a,b) => (a.total_charges > b.total_charges) ? 1 : ((b.total_charges > a.total_charges) ? -1 : 0))
    const handleCreateOrder = ()=>{
        navigate('/order')
    }
    const handlePickup = ()=>{
        navigate('/warehouse')
    }
    const COLUMNS = [
        
        {Header:'Company',accessor:'name'},
        {Header:'Charge',accessor:'total_charges'},
        {Header:'Chargeable Weight in g',accessor:'chargeable_weight'},
        {Header:'Estimated Delivery',accessor:'tat'}
        //{Header:'Action',accessor:'',Cell:props => <button  onClick={(e) => handleShow(e,props)}>Checkout</button>},
    ]
    const columns = useMemo(()=> COLUMNS,[])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns:columns,
        data,
       
        defaultcansort: true,
        initialstate: {
            sortby: [{ id: 'total_charges', desc: true }], // important: use your own data structure and add as many columns that need sorting by default
          },
      },useSortBy)
  return (
    <> 
    <Container>
    
         <table {...getTableProps()}>
        {/* <thead>
            {
                headerGroups.map((headerGroup)=>{

                <tr{...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column)=>{
                        
                        
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>   
                    })}
                    
                </tr>

                })
            }
            

        </thead> */
        <thead>
           
        {
        headerGroups.map(headerGroup => (
          <tr  {...headerGroup.getHeaderGroupProps()}>
            
            {headerGroup.headers.map(column => (
              <th className='header' {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                <span>
                    {column.isSorted? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
 </thead>
        }


        <tbody {...getTableBodyProps()}>
            {rows.map((row,id)=>{
                prepareRow(row)
                return(
                    <tr {...row.getRowProps()}>
                        {
                            row.cells.map((cell)=>{
                                return (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                )

                            })
                        }
                

            </tr>
                )

            })}
            

        </tbody>
    
    
    </table>
    <div className='buttonresponsive'>
        <Button1 onClick={handleCreateOrder}>Create order</Button1>
        <Button onClick={handlePickup}>Add Pickup</Button>
    </div>
    </Container>
    </>
  )
}
const Container = styled.div`
background-color : #f7f3ff !important;
overflow-x:auto; 
.header{
    background-color : #f8f8f8;
    color: rgba(0,0,0,.9);
    border-radius:15px;
    border-bottom:1px solid rgba(0,0,0,.9);;
}

.buttonresponsive{
    @media (max-width: 600px) {
        {
           display: flex;
           flex-direction:column;
           align-self: flex-end;
       }
}
`
const Button = styled.button`


background: #745be7;

color: #fff;
width: 160px;
border-radius: 6px;
font-family: "SFProDisplay-Bold;";
font-weight: 900;
padding: 0;
margin-top: 10px;
cursor: pointer;
height: 30px;
float: right;
box-sizing : content-box;
border: solid 1px #927bfc!important;
 `
 const Button1 = styled.button`


 background: #745be7;
 
 color: #735ae5;
 width: 160px;
 border-radius: 6px;
 font-family: "SFProDisplay-Bold;";
 font-weight: 900;
 padding: 0;
 margin-top: 10px;
 cursor: pointer;
 height: 30px;
 float: left;
 box-sizing : content-box;
 border: solid 1px #927bfc!important;
 background-color: #fff;
       
  `


export default RateCalculator