import React from 'react'
import styled from 'styled-components'
import { useState} from 'react'
import EditWareHouseModal from '../pages/warehouse/EditWareHouseModal'


const Warehouse = ({data}) => {
    
    const [showModal , setShowModal] = useState(false)
    const [pickupData,setPickupData]= useState(null)
    const handleEdit  = (item)=>{
        //console.log("edit",item)
        setShowModal(true)
        setPickupData(item)
    }
  return (
    <Main>
        <Table>
            <thead>
                <tr>
                    <th>WareHouse Name</th>
                    <th>Pincode</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Action</th>
                </tr>
                
                
                    {data.map((item,index)=>{
                        return (
                            <>
                            <tr >
                            <td>
                            <div>
                                <a className='order'>{item.pickup_name}</a>
                                <p>{new Date(item.createdAt).toDateString()}</p>
                                
                                </div>
                            </td>
                         
                            <td>
                                <div>
                                    <p>{item.pickup_pincode}</p>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='' >{item.pickup_address}</p>  
                                    <p className='' >{item.pickup_city}</p>
                                    <p className='' >{item.pickup_state}</p>             
                                        
                                </div>
                            </td>
                            <td>
                                <div>
                                  <p>{item.pickup_phone}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                  <p className='buttonedit' onClick = {()=>handleEdit(item)}>Edit</p>
                                  
                                </div>
                            </td>
                            
                            </tr> </>
                        )
                    })}
                        
                        
                        </thead>
               
            </Table>
            <EditWareHouseModal  isVisible={showModal} onClose={()=>setShowModal(false)} pickupData = {pickupData}></EditWareHouseModal>
          
            
            
    </Main>
  )
}
const Main = styled.section`
    
    
    background-color: #F2FFFA;
    background-color: #f8f8f8;
    background-color: #f7f3ff;
    flex-direction: row !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
    font-family: SFProDisplay-Regular,sans-serif;
    overflow-x:auto;
    .buttonedit{
        
        color:  #212529;
        width: 100px !important;
        text-align: center !important;
        border-radius: 6px;
        font-family: SFProDisplay-Bold;
        font-weight: 500;
        font-size: 15px !important;
        padding-top: 10px ;

        cursor: pointer;
        height: 25px !important;;
        box-sizing : content-box;
        border: solid 1px #927bfc !important;
        
    }

   `
const Table = styled.table`
border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    font-family: SFProDisplay-Regular,sans-serif;
    
     thead{
        vertical-align: bottom;
        z-index: 1;
        position: sticky;
        top: 0;
        border: 0 solid;
        color: black
        border-radius: 8px;
    
    display: table-header-group;
    }
    tr{
        height: 3.834rem;
        vertical-align: middle;
        border-radius: 8px;
        box-shadow: 0 1px 1px rgba(0,0,0,.102);
        border: 0 solid;
        border-color: inherit;
        
    }
    th{
        background-color: #fff;;
        color: black;
        font-family: "SFProDisplay-Medium";
        padding: 1rem;        
    }
    td{
        padding: 0;
    }
    tr:hover{
        background-color: unset ;
    }
  
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonedit{
        


        height: 15px;
        width:50px;
        font-size: 10px;
        background-color: #F65282;
        border-radius: 6px;
        cursor: pointer;
        border: solid 1px #927bfc!important;
        
    }
    .order{
        color: #745be7;
        padding-top:10px;
    }
    .new{
        border-radius: 8px;
        font-size: 20px;
        background-color: #ebf7e8;
        border: solid 1px #fff;
        width: 80px;
        height: 20px;
        color: #008e27;
        font-family: "SFProDisplay-Medium";
    }
`
export default Warehouse