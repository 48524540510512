import React from 'react'
import styled  from 'styled-components'
import Delhivery from '../assets/images/delhivery.com.png'
import expressbees from '../assets/images/xpressbees.png'
import Shadowfax from '../assets/images/shadowfax.png'

const LogoCarousel = () => {
  return (
    <Container>
        
        <div className='logos'>
            <div className='logos-slider'>
                
                <img src={expressbees}  />
                <img src={Delhivery}  />
                <img src={Shadowfax}  />
                

            </div>
            <div className='logos-slider'>
                
                <img src={expressbees}  />
                <img src={Delhivery}  />
                <img src={Shadowfax}  />
                

            </div>
            <div className='logos-slider'>
                
                <img src={expressbees}  />
                <img src={Delhivery}  />
                <img src={Shadowfax}  />
                

            </div>
            <div className='logos-slider'>
                
                <img src={expressbees}  />
                <img src={Delhivery}  />
                <img src={Shadowfax}  />
                

            </div>

        </div>
    </Container>
  )
}

const Container = styled.div`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
background: #f2f2f2;

@keyframes slide{
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.logos{    
    overflow: hidden;
    padding 30px 0;
    background: white;
    white-space: nowrap;
    position: relative;
   
}
.logos:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: linear-gradient(to right ,transparent, white);
    content: " ";
    z-index: 2;
}
.logos:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: linear-gradient(to left ,transparent, white);
    content: "";
    z-index: 2;
}

.logos:hover{
    .logos-slider{
        animation-play-state:paused;
    }
}

.logos-slider{
    display: inline-block;
    
    animation: 15s slide infinite linear;
}
.logos-slider {
    
    img {
        height: 100px;
        margin:10px;
    }

}
`
export default LogoCarousel