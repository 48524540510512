import React,{useMemo, useState} from 'react'
//import { COLUMNS } from './columns'
//import MOCK_DATA from './MOCK_DATA.json'
import {useTable,useSortBy} from 'react-table'
import '../assets/css/table.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import CheckoutModal from '../pages/ship/CheckoutModal'
import { useAppContext } from '../context/appContext'

const CourierTable = ({data,orderid,orderdata}) => {
  data.sort((a,b) => (a.total_charges > b.total_charges) ? 1 : ((b.total_charges > a.total_charges) ? -1 : 0))
    const navigate = useNavigate()
    const [courierVendor, setCourierVendor] = useState(null)
    const [showModal , setShowModal] = useState(false)
    const [shipdata,setShipdata] = useState(null)
    const [shipmentPage,setShipmentPage] = useState(null)
    const {user,isLoading,showAlert,displayAlert,registerUser,loginUser} = useAppContext()

  const onHandleShip1= async()=>{
    
    const transactionId = Math.floor(Date.now() + Math.random())
    const amount = Math.round(courierVendor.total_charges *1.18)
    const paymentData = {transactionId,amount}
    var config = {
      method: 'post',
      url:'/api/v1/payment/',
      headers: {
        'Content-Type': 'application/json',            
        },
        data: paymentData
      
    }
    //console.log(shipData)
    // axios.request(config).then(response=>{
    //   console.log(response.data.instrumentResponse.redirectInfo.url)
    //   navigate(response.data.instrumentResponse.redirectInfo.url)
    // })
    //setShowModal(!showModal)
    localStorage.setItem('shipmentdetails', JSON.stringify({courierVendor,orderdata}));
    localStorage.setItem('transaction', JSON.stringify({transactionId,amount}));
    const response = await axios(config)
    const data = await response.data
    //console.log(data.instrumentResponse.redirectInfo.url)
    //navigate(data.instrumentResponse.redirectInfo.url)
    window.open(data.instrumentResponse.redirectInfo.url,"_parent");
    

  }

  const onHandleShip=async()=>{
    let shipmentData = {}
      //console.log("ship order",courierVendor)
      //console.log(courierVendor)
      if (courierVendor.name.includes('Delhivery')){
         shipmentData = {
          "orderData": orderdata,
          "freightData": courierVendor
        }

      }else{
        //console.log(orderdata)
        let xpress_cod_charges = 0
        if (orderdata.payment_details.toLowerCase() === 'cod'){
           xpress_cod_charges = orderdata.product_price
        }
         shipmentData = {
          "order_number": orderdata._id,
          //"order_number": orderdata._id,
          "unique_order_number":"yes", 
          //"shipping_charges": courierVendor.total_charges/1.11,
          "discount": " ",
          //"cod_charges": 0,
          "payment_type": orderdata.payment_details.toLowerCase(),
          "order_amount": orderdata.product_price,
          "package_weight": orderdata.package_weight,
          "package_length": Math.round(orderdata.package_length),
          "package_breadth": Math.round(orderdata.package_width),
          "package_height": Math.round(orderdata.package_height),
          "request_auto_pickup": "yes",
          "consignee":
                      {
                      "name": orderdata.destination_name,
                      "address": orderdata.destination_address,
                      "address_2": '',
                      "city": orderdata.destination_city,
                      "state": orderdata.destination_state,
                      "pincode": orderdata.destination_pincode,
                      "phone": orderdata.destination_phone,
                      },
          "pickup":
                      {
                       "warehouse_name": orderdata.pickup_name.length<20?orderdata.pickup_name:orderdata.pickup_name.slice(0,20),
                       "name" : orderdata.pickup_name,
                       "address": orderdata.pickup_address,
                       "address_2": " ",
                       "city": orderdata.pickup_city,
                       "state": orderdata.pickup_state,
                       "pincode": orderdata.pickup_pincode,
                       "phone": orderdata.pickup_phone
                      },
          "order_items":
                      [ {
                        "name": orderdata.product_name,
                        "qty": orderdata.product_quantity,
                        "sku": " ",
                        "price": orderdata.product_price,
                        },],
          "courier_id" : courierVendor.id,
          "courier_name":courierVendor.name,
          "collectable_amount":xpress_cod_charges,
          "freightData":courierVendor,
          "orderData":orderdata
                             
        }

      }
      

      //console.log(shipmentData)
      if (orderdata.order_type === 'freight'){
        const shipData = {
          "orderdata": orderdata,
          "freightData": courierVendor
        }
        var config = {
          method: 'post',
          url:'/api/v1/freight/ship',
          headers: {
            'Content-Type': 'application/json',            
            },
          data: shipData
        }
        //console.log(shipData)
        axios(config).then(response=>{
          if(response.status === 200){
            setShipmentPage(response.data)
            navigate('/shipment/'+response.data._id)
          }
        })
        
        //console.log(response)
        return

      }else{
       
        var config = {
          method: 'post',
          url: '/api/v1/courier/ship/',
          headers: {
              'Content-Type': 'application/json',            
          },
          data: shipmentData
         
      }
      //console.log("here")
      axios(config).then(response=>{
        if(response.status === 200){
           setShipmentPage(response.data)
           navigate('/courier/'+response.data._id)
        }
        //console.log(response)
      }).catch(
        setShowModal(false)
      )
      }
     
    
    
      //navigate("/billing", {state:{courierVendor,shipmentData}})
    }
    const COLUMNS = [
    // {Header:'Company',accessor:'courier_name',Cell:props => <a href="#" onClick={(e) => handleShow(e,props)}>{props.value}</a>},
    // {Header:'Charge',accessor:'freight_charge'},
    // {Header:'Delivery Time',accessor:'etd'},

    //{Header:'Company',accessor:'name',Cell:props => <button  onClick={(e) => handleShow(e,props)}>{props.value}</button>},
    {Header:'Company',accessor:'name'},
    {Header:'Charge',accessor:'total_charges'},
    {Header:'Chargeable Weight in g',accessor:'chargeable_weight'},
    {Header:'Estimated Delivery',accessor:'tat'},
    {Header:'Action',accessor:'',Cell:props => <button  onClick={(e) => handleShow(e,props)}>Checkout</button>},
    
    
]
    const columns = useMemo(()=> COLUMNS,[])
    //const data = useMemo(()=> MOCK_DATA,[])
    //console.log(columns)

   /*  const dataTable = useTable ({
        columns:columns,
        data:data
    }) */

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns:columns,
        data,
      },useSortBy)

      const handleShow = (e,cell) => {
        e.preventDefault()
        const courier_vendor = (cell?.row?.original);
        //console.log(courier_vendor)
        setCourierVendor(courier_vendor)
        if(courier_vendor){
          setShowModal(true)
        }else{
          console.log("error")
        }
    }
    

  return (
    <>
     
    <table {...getTableProps()}>
        {/* <thead>
            {
                headerGroups.map((headerGroup)=>{

                <tr{...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column)=>{
                        
                        
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>   
                    })}
                    
                </tr>

                })
            }
            

        </thead> */
        <thead>
           
        {
        headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                <span>
                    {column.isSorted? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
 </thead>
        }


        <tbody {...getTableBodyProps()}>
            {rows.map((row,id)=>{
                prepareRow(row)
                return(
                    <tr {...row.getRowProps()}>
                        {
                            row.cells.map((cell)=>{
                                return (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                )

                            })
                        }
                

            </tr>
                )

            })}
            

        </tbody>
    
    
    </table>
    <CheckoutModal isVisible={showModal} onClose={()=>setShowModal(false)} courier = {courierVendor} 
    onShip={()=>{user.mode === 1 ? onHandleShip():onHandleShip1()}}/>
    </>
  )
}

export default CourierTable