import React from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import styled from 'styled-components'
import {useState, useEffect} from 'react'
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext'
import { FcFullTrash } from 'react-icons/fc';
import * as Yup from 'yup'
import axios from 'axios';
import Warehousecomponent from '../../components/Warehouse';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pincodeRegex = /^[1-9][0-9]{5}$/
const validationSchema1 = Yup.object({
   
    
    pickup_name: Yup.string().required().label("Name"),
    pickup_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    pickup_address: Yup.string().required().min(10).max(200).label("Address"),
    pickup_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    pickup_city: Yup.string().required().min(3).max(30).label('city'),
    pickup_state: Yup.string().required().min(3).max(30).label('state'),
    
  
})
const Warehouse = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [wareHouseData, setWareHouseData] = useState([])
    const [showForm, setShowForm] = useState(true)
    const [data,setData] = useState({
       
        pickup_name: '',
        pickup_phone: '',
        pickup_address: '',
        pickup_pincode:'',
        pickup_city: '',
        pickup_state: '',
       
    })
    const handleSubmit = async(values)=>{
        
        var config = {
            method: 'post',
            url: '/api/v1/warehouse/createwarehouse',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values,
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                let warehouse_id = response_data.data._id.toString()
                console.log(warehouse_id)
                //navigate('/shiporder/'+order_id)
            }
        } catch (error) {
            console.log("error message",error.response)
        }
    }

    const handleAdd= ()=>{
        navigate('/warehouse/create')
    }

    useEffect(()=>{
        const request = axios.get('/api/v1/warehouse/').then((response)=>{
            setLoading(true)
            setWareHouseData(response.data)
            console.log(response.data)
            setLoading(false)
        })
    },[])
  return (
    <>
    {loading && <h3>Loading....</h3>}
        <Main>
        
         { showForm ? (wareHouseData && wareHouseData.length>0 ? <Warehousecomponent data = {wareHouseData}/>: <></> )
        
        : ( 
            
            <Formik 
                validationSchema={validationSchema1}
                initialValues= {data}
            
                onSubmit={handleSubmit} 
            >
            {()=>(

                <> 
                    <Form className='formcontainer'>

                    <h3>Add WareHouse Details</h3>
                        <div className='formrow'>
                            <label>WareHouse Name</label>
                            <Field className = 'inputbox' name = "pickup_name" placeholder= 'Enter Full Name' ></Field>
                            <ErrorMessage name = 'pickup_name'></ErrorMessage>
                            <label>Phone Number</label>
                            <Field className = 'inputbox' name = "pickup_phone" placeholder='Enter phone number' ></Field>
                            <ErrorMessage name = 'pickup_phone'></ErrorMessage>
                        </div>
                        <div className='formrow'>
                            <label>Address</label>
                            <Field className = 'inputbox' name = "pickup_address" placeholder= 'Enter Full Address' ></Field>
                            <ErrorMessage name = 'pickup_address'></ErrorMessage>
                            
                        </div>
                        <div className='formrow'>
                            <label>PinCode</label>
                            <Field className = 'inputbox' name = "pickup_pincode" placeholder= 'Enter PinCode' ></Field>
                            <ErrorMessage name = 'pickup_pincode'></ErrorMessage>
                            <label>City</label>
                            <Field className = 'inputbox' name = "pickup_city" placeholder='Enter City' ></Field>
                            <ErrorMessage name = 'pickup_city'></ErrorMessage>
                            <label>State</label>
                            <Field className = 'inputbox' name = "pickup_state" placeholder='Enter State' ></Field>
                            <ErrorMessage name = 'pickup_state'></ErrorMessage>
                        </div>
                        
                        <div className='buttonrow'>
                            <button className = 'buttonnext' type="submit" >Create</button>
                    </div> 
                    </Form>
                </>
            )}
            </Formik>
            
        ) }
        <button className='buttonnext' onClick={handleAdd}>Add Pickup</button>
        </Main>
    </> 
    )
}
const H3 = styled.h3`
    text-align: center;
    margin-bottom:30px;
    font-family: "SFProDisplay-Bold";
    font-weight: 900;
    color: #F65282;
   span{
        background-color: rgba(255, 234, 234, 0.57);
    }
    label{
        font-size: 14px;
        color: #555;
    }
    
   
    `
    const Main = styled.div`
    margin-top: 10px;
    font-family: SFProDisplay-Bold;
    
        h3{
            line-height: 16px!important;
            font-weight: 900;
            
            margin-bottom: 1.5rem!important;
            font-family: SFProDisplay-Bold;
                    
        }
        label{
            color: #F65282;
            margin-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
    
        .formcontainer{
            display:flex;
            flex-direction: column;
        }
        .formrow{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            text-align: center;
        }
    
        .inputbox{
        height: 36px;
        border-radius: 6px;
        background-color: #fff;
        color: #505050!important;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: solid 1px #d3d3d3;
        padding-left: 10px;
        }
    
        .field1{
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
        }
    
        .buttonrow{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
            
        }
        .buttonnext{
            background-color: #745be7;
            color: #fff;
            width: 60px;
            border-radius: 6px;
            font-family: "SFProDisplay-Bold;";
            font-weight: 900;
            padding: 0;
            cursor: pointer;
            height: 30px;
            box-sizing : content-box;
            border: solid 1px #927bfc!important;
           width:100px;

            
        }
        .buttonback{
            background-color: #fff;
            color: #735ae5;
            width: 60px;
            border-radius: 6px;
            font-family: "SFProDisplay-Bold;";
            font-weight: 900;
            padding: 0;
            cursor: pointer;
            height: 30px;
            border: solid 1px #927bfc!important;
            text-align: center;
            box-sizing : content-box;
            margin-right: 1.33rem;
            
            
            
        }
        .formrowbackground{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            text-align: center;
            background-color: #e0fcff;
            
        }
        .product{
            color:black;
            font-size: 20px;
            margin-top: 10px;
        }
        .box{
            border: none;
            border-bottom: 1px solid #a6a6a6;
            border-radius: None;
            background: transparent;
        }
        .buttonAdd{
            border-radius: 4px;
            background-color: #f0503c;
            width: 102px;
            height: 32px;
            color: #fff;
            font-size: 14px;
            border: 1px solid #f0503c;
            cursor:pointer;
            margin: 10px;
           
        }
        .trashbox{
            background: transparent;
            border:none;
            cursor:pointer;
        }
    }
    
    `

export default Warehouse