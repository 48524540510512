import React, {useState} from 'react'
import styled from 'styled-components'
import axios from 'axios';
import CourierTrackingModal from '../pages/tracking/CourierTrackingModal.js'
import XpressTrackingModal from '../pages/tracking/XpressTrackingModal.js';

const CourierDetail = ({data,index}) => {
    const [showModal , setShowModal] = useState(false)
    const [courierTrackingData, setCourierTrackingData] = useState('')
    const [xpressBeesTrackingData, setXpressBeesTrackingData] = useState('')
    const handleLabel =  ()=>{
        //console.log(data)
        if (data.courierName.includes('Delhivery')){
            const labelData = {"courierName":data.courierName,"tracking":data.tracking}
                var config = {
                    method: 'post',
                    url: '/api/v1/courier/label/',
                    headers: {
                        'Content-Type': 'application/json',            
                    },
                    data: labelData
                
                }
            axios(config).then(response=>{   
                //console.log(response.data)         
                window.open(response.data,'_blank')
         })

        }else{
            
            window.open(data.courierLabel,'_blank')
        }
        
        
    }
    const handleTracking = ()=>{
        if (data.courierName.includes('Delhivery')){
            const trackingData = {"courierName":data.courierName,"tracking":data.tracking}
            var config = {
                method: 'post',
                url: '/api/v1/courier/track',
                headers: {
                    'Content-Type': 'application/json',            
                },
                data: trackingData           
            }
            axios(config).then(response=>{
            
                setCourierTrackingData(response.data.reverse())
                
                setShowModal(true)
            })

        }else{
            const trackingData = {"courierName":data.courierName,"tracking":data.tracking}
            var config = {
                method: 'post',
                url: '/api/v1/courier/track',
                headers: {
                    'Content-Type': 'application/json',            
                },
                data: trackingData           
            }
            axios(config).then(response=>{
                //console.log(response.data.data.history)
                setXpressBeesTrackingData(response.data.data.history)
                //console.log(response.data.data.history)
                
                setShowModal(true)
            })
        }
        
        
    }
    return (
        
        <Main>
            <Table>
                <thead>
                    <tr className={index !== 0?'show':''}>
                        <th>Company Name</th>
                        <th>Package Details</th>
                        <th>Order Id</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    
                    <tr >
                            <td>
                                <div>
                                    <a className='order'>{data.courierName}</a>
                                    <p>Tracking Number: <span className='track' onClick = {handleTracking}>{data.tracking}</span></p>
                                                                 
                                    </div>
                                </td>
                                <td>
                                    <div>
                                    <><p>Chargeable Weight: {data.courierWeight}g</p>
                                        <p>Freight Charges: {parseFloat(data.courierCharges).toFixed(2)}</p></>
                                         
                                        
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{data.orderId}</p>
                                        {data.orderNumber && <p>Ref Number: {data.orderNumber}</p>}
    
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {/* <p className='new'>{data.courierPickupStatus ? data.courierPickupStatus: 'Create Pickup Request' }    </p> */}
                                        <button className='buttonnext' type='submit' onClick={handleTracking}>Track</button>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                    
                                        <button className='buttonnext' type='submit' onClick={handleLabel}>Print Label</button>
                                    
                                    
                                    </div>
                                </td>
                            </tr> 
                            </thead>
                   
                </Table>
            {courierTrackingData && <CourierTrackingModal isVisible={showModal} onClose={()=>setShowModal(false) } data={courierTrackingData}/> }
            {xpressBeesTrackingData && <XpressTrackingModal isVisible={showModal} onClose={()=>setShowModal(false) } data={xpressBeesTrackingData}/> }
               
                
                
                
                
        </Main>
        
        
      )
}
const Main = styled.section`
    
    
    background-color: #F2FFFA;
    background-color: #f8f8f8;
    background-color: #f7f3ff;
    flex-direction: row !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
    font-family: SFProDisplay-Regular,sans-serif;
    overflow-x:auto; 
    .buttonedit{
        background-color: #F65282;;
        color:  #212529;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }

   `
const Table = styled.table`
border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    font-family: SFProDisplay-Regular,sans-serif;
    
    
     thead{
        vertical-align: bottom;
        z-index: 1;
        position: sticky;
        top: 0;
        border: 0 solid;
        color: black
        border-radius: 8px;
    
    display: table-header-group;
    }
    tr{
        height: 3.834rem;
        vertical-align: middle;
        border-radius: 8px;
        box-shadow: 0 1px 1px rgba(0,0,0,.102);
        border: 0 solid;
        border-color: inherit;
        
    }
    th{
        background-color: #fff;
        color: black;
        font-family: "SFProDisplay-Medium";
        padding: 1rem;        
    }
    td{
        padding: 0;
    }
    tr:hover{
        background-color: unset ;
    }
  
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 80px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonedit{
        


        height: 15px;
        width:50px;
        font-size: 10px;
        background-color: #F65282;
        border-radius: 6px;
        cursor: pointer;
        border: solid 1px #927bfc!important;
        
    }
    .order{
        color: #745be7;
        padding-top:10px;
    }
    .new{
        border-radius: 8px;
        font-size: 20px;
        background-color: #ebf7e8;
        border: solid 1px #fff;
        width: 80px;
       
        color: #008e27;
        font-family: "SFProDisplay-Medium";
    }
    .track{
        color:red;
        cursor: pointer;
    }
    .show{
        display:none;
    }
`

export default CourierDetail