import styled from 'styled-components'
import React from 'react'
import axios from 'axios'
import {useState} from 'react'
import Tdoc from '../../assets/files/Courierdeal.com merchant Agreement.doc'

const CheckoutModal = ({isVisible,onClose,courier,onShip}) => {
  const [agree, setAgree] = useState(false);
    if(!isVisible) return null
    //console.log(courier)
    const handleSubmit =async (e)=>{
        e.preventDefault()
        //console.log("ok")          
    }
    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }
  return (
    <Main>
       <div id="myModal" className="modal">



        <div className="modal-content">
            <button className="close" onClick={()=>onClose()}>&times;</button>
            
            <div className='data'>
                <h3>Payment Details</h3>
                <div className='details'>
                <div className='detailrow'>
                        <span>Courier Partner</span>
                        <span>{courier.name}</span>
                    </div>
                    <div className='detailrow'>
                        <span>Chargeable Weight</span>
                        <span>{courier.chargeable_weight}g</span>
                    </div>
                    <div className='detailrow'>                    
                        <span>Total Freight Charges</span>
                        <span>{courier.total_charges}</span>
                    </div>
                    <div className='detailrow'>                    
                        <span>COD Charges</span>
                        <span>{courier.cod_charges}</span>
                    </div>
                    <div className='detailrow'>                    
                        <span>GST</span>
                        <span>{courier.total_charges * 0.18}</span>
                    </div>
                    <hr className='hr'></hr>
                    <div className='detailrow'>
                        <strong>Total Charges</strong>
                       <span>{Math.round(courier.total_charges *1.18)}</span>
                    </div>
                  {/* <label>payment details</label>
                  <span>Account No: 123456789</span>
                  <span>IFSC Code HDFC001245</span> */}
                </div>
                
            </div>
            
            <div>
          <input type="checkbox" id="agree" onChange={checkboxHandler} />
          <label>  I agree to </label><a href={Tdoc} target="_blank" rel="noreferrer"> <b>T&C</b></a>
          
        </div>
            <button disabled={!agree} className='button' type="submit" onClick={()=>onShip()}>Continue To Payment</button>
        </div>


    </div>
    </Main>
  )
}
const Main = styled.div`
.modal {
     /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    border-radius: 8px;
  }
  
  /* The Close Button */
  .close {
    color: #F65282;
    float: right;
    font-size: 28px;
    font-weight: bold;
    border: 0;
    background: transparent;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .data{
    display:flex;
    flex-direction: column;
    background-color: #f2f8ff;
    border-radius: 8px;
    
  }
  .details{
    display:flex;
    flex-direction: column;
    background-color: #f6f6f6;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 1rem;
  }
  .detailrow{
    margin-bottom: 0.25rem;
    display:flex;
    justify-content: space-between;
    width: 50%;
    padding:10px;
  }
  .hr{
    color: #e3e3e3;
    height: 1px;
    margin: 0.5rem;
  }
  .strong{
    font-weight: bolder;
  }
  .button{
    background-color: #745be7   ;
    color: #fff;
    width: 200px;
    border-radius: 6px;
    font-family: "SFProDisplay-Bold";
    font-weight: 900;
    padding: 0;
    cursor: pointer;
    height: 36px;
    box-sizing : content-box;
    border: solid 1px #927bfc!important;
    margin-top: 10px;
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.4;
  }



`
export default CheckoutModal