import React, {useState} from 'react'
import styled from 'styled-components'

const CourierTrackingModal = ({isVisible,onClose,data}) => {
    const [agree, setAgree] = useState(false);
    if(!isVisible) return null
  return (
  
    <Main>
            <div id="myModal" className="modal">

            <div className="modal-content">
                <button className="close" onClick={()=>onClose()}>&times;</button>
                
                <div className='data'>
                    <h3>Tracking Information</h3>
                    {data.map((scan,index) => (
                        <>
                            <div className='details'>
                        <div className='detailrow'>
                            <span className='spanLable'>Scan</span>
                            <span>{scan.ScanDetail.Scan}</span>
                        </div>
                        <div className='detailrow'>
                            <span className='spanLable'>ScannedLocation</span>
                            <span>{scan.ScanDetail.ScannedLocation}</span>
                        </div>
                        <div className='detailrow'>                    
                            <span className='spanLable'>Status Date Time</span>
                            <span>{scan.ScanDetail.ScanDateTime}</span>
                        </div>
                        <div className='detailrow'>                    
                            <span className='spanLable'>Status Update</span>
                            <span>{scan.ScanDetail.Instructions}</span>
                        </div>
                        
                        
                 
                    </div>
                        </>
        
      ))}
                    {/* <div className='details'>
                        <div className='detailrow'>
                            <span>Status</span>
                            <span>{data.Status}</span>
                        </div>
                        <div className='detailrow'>
                            <span>Status Location</span>
                            <span>{data.StatusLocation}g</span>
                        </div>
                        <div className='detailrow'>                    
                            <span>Status Date Time</span>
                            <span>{data.StatusDateTime}</span>
                        </div>
                        
                        
                 
                    </div> */}
                    
                </div>
            </div>   
             
            


        </div>
    </Main>
  )
}
const Main = styled.div`
.modal {
     /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    z-index:2;
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    border-radius: 8px;
    
  }
  
  /* The Close Button */
  .close {
    color: #F65282;
    float: right;
    font-size: 28px;
    font-weight: bold;
    border: 0;
    background: transparent;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .data{
    display:flex;
    flex-direction: column;
    background-color: #f2f8ff;
    border-radius: 8px;
    
  }
  .details{
    display:flex;
    flex-direction: column;
    background-color: #f6f6f6;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 1rem;
  }
  .detailrow{
    margin-bottom: 0.25rem;
    display:flex;
    justify-content: space-between;
    width: 50%;
    padding:10px;
    @media (max-width: 600px){
      display:flex;
      flex-direction:column;
      gap:10px;
    }
  }
  .hr{
    color: #e3e3e3;
    height: 1px;
    margin: 0.5rem;
  }
  .strong{
    font-weight: bolder;
  }
  .button{
    background-color: #745be7   ;
    color: #fff;
    width: 200px;
    border-radius: 6px;
    font-family: "SFProDisplay-Bold";
    font-weight: 900;
    padding: 0;
    cursor: pointer;
    height: 36px;
    box-sizing : content-box;
    border: solid 1px #927bfc!important;
    margin-top: 10px;
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.4;
  }
  .spanLable{
    font-weight: 600;
    color: #885df1;
    
}



`
export default CourierTrackingModal