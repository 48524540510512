import React from 'react'
import { Formik, Form, Field,ErrorMessage} from 'formik';
import { useLocation, useParams , useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async'
import axios from 'axios';
import {useState} from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pincodeRegex = /^[1-9][0-9]{5}$/
const stepTwoValidationSchema = Yup.object({
    pickup_name: Yup.string().required().label("Name"),
    pickup_phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).label("Phone"),
    pickup_address: Yup.string().required().min(10).max(200).label("Address"),
    pickup_pincode: Yup.string().required().matches(pincodeRegex, 'invalid pincode').label('pincode'),
    pickup_city: Yup.string().required().min(3).max(30).label('city'),
    pickup_state: Yup.string().required().min(3).max(30).label('state')
    

})


const StepTwo = (props)=>{
    const [wareHouseErrorMessage, setWareHouseErrorMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState({});
    const navigate = useNavigate()
    const getWareHouseData = async()=>{
        var config = {
            method: 'get',
            url: '/api/v1/warehouse/',
            headers: {
                'Content-Type': 'application/json'
            },
            
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                //let warehouse_id = response_data.data._id.toString()
                console.log(response_data.data)
                return response_data.data
                //navigate('/shiporder/'+order_id)
            }
        } catch (error) {
            console.log("error message",error.response)
        }
    }
    const handleInputChange = values => {
        setInputValue(values);
        //console.log("input",inputValue)
      };
    
      // handle selection
      const handleChange = values => {
        //setSelectedValue(value);
        setSelectedValue(values)
        //console.log(selectedValue)
        setWareHouseErrorMessage('')
        
      }
    const handleSubmit = (values)=>{
        //console.log(values)
        //console.log("select",selectedValue)
        values = {...values,order_type:'Courier',pickup_name:selectedValue.pickup_name,
        pickup_address:selectedValue.pickup_address,
        pickup_phone:selectedValue.pickup_phone,pickup_pincode:selectedValue.pickup_pincode,
        pickup_city:selectedValue.pickup_city,pickup_state:selectedValue.pickup_state}
        props.next(values)
    }
    const handleCreatewareHouse = ()=>{
        navigate('/warehouse/create')
    }
    return(
        <Main>
            <h3>Add PickUp Details</h3>
        <Formik 
            //validationSchema={stepTwoValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
        >
            {({values})=>(
            <>
                <Form className='formcontainer'>
                {/* <div className='formrow'>
                    <label>Full Name</label>
                    <Field className = 'inputbox' name = "pickup_name" placeholder= 'Enter Full Name'></Field>
                    <ErrorMessage name = 'pickup_name'></ErrorMessage>
                    <label>Phone Number</label>
                    <Field className = 'inputbox' name = "pickup_phone" placeholder='Enter phone number'></Field>
                    <ErrorMessage name = 'pickup_phone'></ErrorMessage>
                </div>
                <div className='formrow'>
                    <label>Address</label>
                    <Field className = 'inputbox' name = "pickup_address" placeholder= 'Enter Full Address'></Field>
                    <ErrorMessage name = 'pickup_address'></ErrorMessage>
                    
                </div>
                <div className='formrow'>
                    <label>PinCode</label>
                    <Field className = 'inputbox' name = "pickup_pincode" placeholder= 'Enter PinCode'></Field>
                    <ErrorMessage name = 'pickup_pincode'></ErrorMessage>
                    <label>City</label>
                    <Field className = 'inputbox' name = "pickup_city" placeholder='Enter City'></Field>
                    <ErrorMessage name = 'pickup_city'></ErrorMessage>
                    <label>State</label>
                    <Field className = 'inputbox' name = "pickup_state" placeholder='Enter State'></Field>
                    <ErrorMessage name = 'pickup_state'></ErrorMessage>
                </div> */}
                <div className='formrow'>
                    <label>WareHouse Name</label>
                    <AsyncSelect
                            cacheOptions
                            defaultOptions
                            
                            getOptionLabel={e => e.pickup_name}
                            getOptionValue={e => e.id}
                            loadOptions={getWareHouseData}
                            onInputChange={handleInputChange}
                            onChange={ handleChange }
                            noOptionsMessage={()=>"WareHouse not found"}
                            
                    />
                    <button className='pickup' onClick={handleCreatewareHouse}>+ Add Pickup</button>
                    {wareHouseErrorMessage && <p>Please select warehouse,if not available please create new warehouse</p>}
                </div>
                <div>
                    
                </div>
                <div className='buttonrow'>
                    <button className = 'buttonback' type = "button" onClick={()=>props.prev(values)}>Back</button>
                    <button className = 'buttonnext' type = "submit" >Next</button>
                    {/* <button type="submit">Next</button> */}
                </div>
               
                
                </Form>
            </>
            )}
        </Formik>
        </Main>
    )

}

export default StepTwo

const Main = styled.div`

margin-top: 10px;
font-family: SFProDisplay-Bold;

    h3{
        line-height: 16px!important;
        font-weight: 900;
        
        margin-bottom: 1.5rem!important;
        font-family: SFProDisplay-Bold;
        @media(max-width:980px){
            line-height: 5px;
            display: block;
            font-size: 15px;

        }
                
    }
    label{
        color: #F65282;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .formcontainer{
        display:flex;
        flex-direction: column;
    }
    .formrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        @media(max-width:980px){
            display:flex;
            flex-direction:column;
        }
    }

    .inputbox{
    height: 36px;
    border-radius: 6px;
    background-color: #fff;
    color: #505050!important;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: solid 1px #d3d3d3;
    padding-left: 10px;
    }

    .field1{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
    }

    .buttonrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        
    }
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 60px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonback{
        background-color: #fff;
        color: #735ae5;
        width: 60px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        border: solid 1px #927bfc!important;
        text-align: center;
        box-sizing : content-box;
        margin-right: 1.33rem;
        
        
        
    }
    .pickup{
        border-radius: 4px;
        background-color: #ebf7e8;
        width: 102px;
        height: 32px;
        color: #745be7;
        font-size: 13px;
        border: none;
        cursor:pointer;
        padding: 10px;
        margin: 10px;
        color: #008e27;
    }
}

`