import React, { useEffect } from 'react'
import { useLayoutEffect,useState} from 'react';
import { useLocation, useParams,useNavigate } from 'react-router-dom';
import ShipmentDetail from '../../components/ShipmentDetail';
import styled from 'styled-components'
import axios from 'axios';

const ShipmentDetails = () => {
const {id} = useParams(true)
const [shipment,setShipment] = useState(null)
const [label,setLabel] = useState([])
const [loading,setLoading] = useState(null)
const [image, setImage] = useState([])
const [trackingInfo, setTrackingInfo] = useState()
const [trackLoading,setTrackLoading] = useState(false)
const image1 = []
const[error,setError] = useState()
const handleLabel = async()=>{
 
  Promise.all(label.map((url) => {
    return axios.get(url).then(({ data }) => {
        //console.log(data);
        //let buffer1 =  Buffer(data, 'binary').toString('base64');
        //let data_buffer = Buffer.from(buffer1, 'base64')
        image1.push(data)
    });
})).then(_=>setImage(image1));
  // console.log(label)
  // setLoading(true)
  // const imagedata = await axios.all(label.map((endpoint) => axios.get(endpoint,{ responseType: 'arraybuffer'})))
  // const imagedata1 = await imagedata.data
  
  // console.log(imagedata1)
  // setLoading(false)
  // const labeldata = await axios.get(`/api/v1/freight/ship/label/${shipment.lrnum}`)  
  // setLabel(labeldata)
  
  // axios.all(label.map((endpoint) => axios.get(endpoint))).then(
    
  //   (data) => {
  //     console.log(data)
  //   }
  // )
  // Promise.all(label.map((endpoint) => axios.get(endpoint))).then(
  //   axios.spread((...allData) => {
  //     console.log({ allData });
  //   })
  // );
  // setLoading(false)
  // console.log(image)
}

const handleTrackInfo = async()=>{
  setTrackLoading(true)
  
  var config = {   
    method: 'post',    
    url: '/api/v1/freight/ship/track',
    headers: {
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer '+accessToken
    },
    data: {"trackinginfo":shipment.lrnum}    
}
  try {
    const response = await axios(config)
    setTrackingInfo(response.data.data)
    setTrackLoading(false)
  } catch (error) {
    setError('Something went wrong, please try again later')
    setTrackLoading(false)
  }
 
}

const handlePickup = ()=>{

}
useEffect( () => {
    if (!id) {
      return;
    }
    const request = axios.get(`/api/v1/freight/ship/${id}`).then(response => {
      setLoading(true)
      setShipment(response.data);   
    
    //return axios.get(`/api/v1/freight/ship/label/${shipment.lrnum}`)
    return response.data.lrnum
    }).then(lrnum=>{
      return axios.get(`/api/v1/freight/ship/label/${lrnum}`)
    }).then(response=>{
      setLabel(response.data.data)
      setLoading(false)
      return response.data

    })
 

  //   axios.get('1st api call').then(res1 => {
  //     //some code related to 1st
  //     return axios.get('2nd api call')
  // }).then(res2 => {
  //     //some other code related to 2nd
  //     return axios.get('3rd api call')
  // }).then(res3 => {
  //     //some other code related to 3rd
  // })
    
  //   console.log(shipment,id)
    
}, []);

  return (
  <>

    {loading && <h1>Loading....</h1>}

    {shipment && 
      <Main>
        <h3 className='h13'>Shipment Details</h3>
        <ShipmentDetail data ={shipment}/>
          <ButtomContainer>
          <button className = 'button' type='button' onClick={handleLabel}>Show Label</button>
          <button className = 'button' type='button' onClick={handleTrackInfo}>Tracking</button>
          <button className = 'button' type='button' onClick={handlePickup}>Pickup</button>
          </ButtomContainer>
            
            {image.length>0 && image.map((item,index)=>{
              return(
                <>
                <a href = {item} download="image"><img src={item}  width="300" height="300"></img></a>
                </>
              )
            })}
          <div>
              
              {trackLoading && <h1>Loading....</h1>}
              {trackingInfo && 
            <>
              {trackingInfo.wbns.map((item,index)=>{
                return (
                <>
                <Tracking>
                  <div>
                    <span>Status</span>
                    <a> {item.status}</a>
                  </div>
                  <div>
                      <span>Location</span>
                      <a>{item.location}</a>
                    </div>
                    <div>
                      <span>Manifested Date</span>
                      <a>{item.manifested_date}</a>
                    </div>
                    
                    <div>
                    <span>Scan remark</span>
                      <a>{item.scan_remark}</a>
                    </div>
                </Tracking>
                </>
                )

              })}
            </>}
           
          </div>
            
      </Main>
    }
    
  </>
  )
}

const Main = styled.section`
.button{
  background-color: #745be7;
  color: #fff;
  width: 120px;
  border-radius: 6px;
  font-family: "SFProDisplay-Bold;";
  font-weight: 900;
  padding: 0;
  cursor: pointer;
  height: 30px;
  box-sizing : content-box;
  border: solid 1px #927bfc!important;

  button:hover {
    background-color: #3e8e41;
  }

  img{
    padding:10px;
    background: #f8f8f8;
    padding-top:20px;
    border-bottom: 1px solid #dbdbdb;
  }

  h3{
    text-shadow: 0 2px white, 0 3px #777;
  }

  .h13{
    linear-gradient(to bottom left, #553c9a, #ee4b2b);font-size: 70px;
    font-weight: 600;
    background-image: linear-gradient(to left, #553c9a, #b393d3);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    color:red;
  }
  
  
}
`
const ButtomContainer = styled.div`
display: flex;
justify-content: space-between;padding-bottom:20px;
`

const Tracking = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-end;
margin-top:20px;
background: #f8f8f8;
border-bottom: 1px solid #dbdbdb;
border-radius: 6px;
  div{
    display: flex;
    flex-direction: column
    
  }
`

export default ShipmentDetails