import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {FaCheck} from "react-icons/fa";

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import styled from 'styled-components'
//import MultiStepProgressBar from './MultiStepProgressBar';

const Order = () => {
    const navigate = useNavigate()
    const [data,setData] = useState({
        destination_name :'',
        destination_phone: '',
        destination_address: '',
        destination_pincode: '',
        destination_city: '',
        destination_state: '',
        pickup_name: '',
        pickup_phone: '',
        pickup_address: '',
        pickup_pincode:'',
        pickup_city: '',
        pickup_state: '',
        package_weight: '',
        package_length: '',
        package_width: '',
        package_height: '',
        payment_details: '',
        product_name:  '',
        product_quantity: '',
        product_price: '',
        package_number: '',
        fragile:false,
        orderNumber:'',
        
    })

    const [currentStep,setCurrentStep] = useState(0)
    
    const makeRequest = async(formData)=>{
        
        var config = {
            method: 'post',
            url: '/api/v1/order/createorder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData,
        }
        try {
            const response_data = await axios(config)
            
            if (response_data.status == 200){
                let order_id = response_data.data._id.toString()
                //console.log(order_id)
                navigate('/shiporder/'+order_id)
            }
        } catch (error) {
            console.log("error message",error.response)
        }
        
    }
    const handleNextStep = (newData,final=false)=>{
        
        setData(prev=>({...prev,...newData}))

        if (final){
            makeRequest(newData)
            return
        }
        setCurrentStep(prev => prev+1)

    }
    const handlePrevStep = (newData)=>{
        
        setData(prev=>({...prev,...newData}))
        setCurrentStep(prev => prev-1)

    }
    const steps = [<StepOne next={handleNextStep} data={data} />,
                    <StepTwo next={handleNextStep} prev = {handlePrevStep} data = {data} />,
                    <StepThree next={handleNextStep} prev = {handlePrevStep} data = {data} />
                ]
    //console.log(data)

  return (
    <Main>
        <SideBar>
           <Orderbar data = {currentStep} />
         
       
        </SideBar>
        

       <div >
            {steps[currentStep]}
        </div> 

    </Main>
  )

  


}

const Orderbar=(prop)=>{
    console.log(prop.data)
    return (
        <>              

            <FormName> 
                {prop.data ===0 ? <span className={prop.data === 0 ?'showactive':'nonactive'}>1</span>: <span className='showactivecomplete'><FaCheck /></span>}
                                
                <p className={prop.data === 0 ?'pshowactive':'pnonactive'}>Destination Details</p>
            </FormName>
 
            <FormName>
            {prop.data ===1 ? <span className={prop.data === 1 ?'showactive':'nonactive'}>2</span>:prop.data > 1 ? <span className='showactivecomplete'><FaCheck /></span>:<span className={prop.data === 1 ?'showactive':'nonactive'}>2</span>}
                {/* <span className={prop.data === 1 ?'showactive':'nonactive'}>2</span> */}
                <p className={prop.data === 1 ?'pshowactive':'pnonactive'}>PickUp Details</p>
                
            </FormName>

            <FormName> 
                <span className={prop.data === 2 ?'showactive':'nonactive'}>3</span>
                <p className={prop.data === 2 ?'pshowactive':'pnonactive'}>Order Details</p>
            </FormName>

            {/* <FormName> 
                <span className={prop.data === 3 ?'showactive':'nonactive'}>4</span>
                <p className={prop.data === 3 ?'pshowactive':'pnonactive'}>Package Details</p>
            </FormName> */}
            
        </>
    )
}



const Main = styled.section`
    display : flex;
    
    background-color: #F2FFFA;
    background-color: #f8f8f8;
    background-color: #f7f3ff;
    flex-direction: row !important;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
    font-family: SFProDisplay-Regular,sans-serif;
`

const SideBar = styled.div`
    padding: 4px;
    margin: 20px;
    width: 200px;
`
const FormName = styled.div.attrs({})`
    display: flex;
    align-items: center;    
    color: #191919;
    font-family: SFProDisplay-Medium;
    font-size: 14px;
    opacity:1;
    span{
        margin: 5px;        
        font-size: 14px;
        border: 1px solid #745be7;
        border-radius: 20px;
        display: inline-block;
        width: 22px;
        text-align: center;
        color: #333;
        background-color: #fff;
        margin-right: 30px;
        padding-top: 2px;        
    }
    .showactive{        
        background-color: #fff;        
        opacity: 1;        
    }
    .nonactive{       
        
        border-color: #d3d3d3 !important;
        opacity: 0.6;
    }
    .pshowactive{        
        
      font-weight: bold;
      color: #885df1
        
    }
    .pnonactive{
        border-color: #d3d3d3 !important;
        color: rgba(0,0,0,.38);
        font-weight: 100;
    }
    .showactivecomplete{        
        background-color: #008e27;;        
        opacity: 1;
        color:white;       
    }
    .pshowactivecomplete{        
        
        font-weight: bold;
        color: #008e27;;
          
      }
   

   
      
    p{
        font-size: 18px;
        font-weight: 500;
    }
`



export default Order