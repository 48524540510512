import React from 'react'
import { Formik, Form, Field, ErrorMessage} from 'formik';
import {useState} from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

const stepThreeValidationSchema = Yup.object({
    package_weight: Yup.number().typeError('Must be a number').required().label("Weight"),
    package_length: Yup.number().typeError('Must be a number').required().label("Length"),
    package_width: Yup.number().typeError('Must be a number').required().label("Breadth"),
    package_height: Yup.number().typeError('Must be a number').required().label('Height'),
    payment_details: Yup.string().required().label('Payment Option'),
    product_name: Yup.string().required().min(3).max(30).label('Product'),
    product_quantity: Yup.number().typeError('Must be a number').required().label('Quantity'),
    product_price: Yup.number().typeError('Must be a number').required().label('Total Price'),
    orderNumber: Yup.string().max(30)
    

})

const StepThree = (props) => {
    const [box,setbox] = useState()
    const handleSubmit = (values)=>{
        props.next(values,true)
     }
  return (
    <Main>
            <h3>Add Orders Details</h3>
        <Formik 
        validationSchema={stepThreeValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
        >
            {({values})=>(
            <>
                <Form className='formcontainer'>
                <div className='formrow'>
                    <label>Dead Weight in g</label>
                    <Field className = 'inputbox' name = "package_weight" placeholder= 'Enter Weight in gram'></Field>
                    <ErrorMessage name = 'package_weight'></ErrorMessage>
                    {/* <label>Number of boxes</label>
                    <Field className = 'inputbox' name = "package_number" placeholder= 'Enter number of boxes'></Field> */}
                    <label>Order Number</label>
                    <Field className = 'inputbox' name = "orderNumber" placeholder= 'Reference order number'></Field>
                </div>
                <div className='formrowbackground'>
                    <label className='product' >Dimensions in Centimeter</label>
                    
                </div>
                {values.package_weight >= 20000 ? values.package_number > 1 ? <p>{values.package_number}</p>:<></>:<></>}
                <div className='formrow'>
                    <label>Length</label>
                    <Field className = 'inputbox' name = "package_length" placeholder= 'Length in cm'></Field>
                    <ErrorMessage name = 'package_length'></ErrorMessage>
                    <label>Width</label>
                    <Field className = 'inputbox' name = "package_width" placeholder='Width in cm'></Field>
                    <ErrorMessage name = 'package_width'></ErrorMessage>
                    <label>Height</label>
                    <Field className = 'inputbox' name = "package_height" placeholder='Height in cm'></Field>
                    <ErrorMessage name = 'package_height'></ErrorMessage>
                </div>
                <div className='formrowbackground'>
                    <label className='product'>Payment Details</label>
                    
                </div>
                <div className='formrow'>
                    <label>Prepaid</label>
                    <Field type = 'radio' value = 'prepaid' name = "payment_details" ></Field>
                    <label>COD</label>
                    <Field type = 'radio' value = 'cod' name = "payment_details" ></Field>
                    <ErrorMessage name = 'payment_details'></ErrorMessage>
                </div>
                <div className='formrowbackground'>
                    <label className='product'>My Package Contains Fragile Items</label>
                    
                </div>
                <div className='formrow'>
                    <label>Fragile</label>
                    <Field type = 'checkbox'  name = "fragile" ></Field>
                    
                </div>
                
                <div>
                <div className='formrowbackground'>
                    <label className='product'>Product Details</label>
                    
                </div>
                <div className='formrow'>
                    <label>Name</label>
                    <Field className = 'inputbox' name = "product_name" placeholder= 'Name of product'></Field>
                    <ErrorMessage name = 'product_name'></ErrorMessage>
                    <label>Quantity</label>
                    <Field className = 'inputbox' name = "product_quantity" placeholder='Quantity'></Field>
                    <ErrorMessage name = 'product_quantity'></ErrorMessage>
                    <label>Price</label>
                    <Field className = 'inputbox' name = "product_price" placeholder='Total Price'></Field>
                    <ErrorMessage name = 'product_price'></ErrorMessage>
                </div>
                </div>
                <div className='buttonrow'>
                    <button className = 'buttonback' type = "button" onClick={()=>props.prev(values)}>Back</button>
                    <button className = 'buttonnext' type="submit">Submit</button>
                </div>
                </Form>
            </>
            )}
        </Formik>
        </Main>
  )
}

export default StepThree



const Main = styled.div`
margin-top: 10px;
font-family: SFProDisplay-Bold;

    h3{
        line-height: 16px!important;
        font-weight: 900;
        
        margin-bottom: 1.5rem!important;
        font-family: SFProDisplay-Bold;
        @media(max-width:980px){
            line-height: 5px;
            display: block;
            font-size: 15px;

        }
                
    }
    label{
        color: #F65282;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .formcontainer{
        display:flex;
        flex-direction: column;
    }
    .formrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        text-align: center;
        @media(max-width:980px){
            display:flex;
            flex-direction:column;
        }
    }

    .inputbox{
    height: 36px;
    border-radius: 6px;
    background-color: #fff;
    color: #505050!important;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: solid 1px #d3d3d3;
    padding-left: 10px;
    }

    .field1{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,0.25);
    }

    .buttonrow{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        
    }
    .buttonnext{
        background-color: #745be7;
        color: #fff;
        width: 60px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        box-sizing : content-box;
        border: solid 1px #927bfc!important;
        
    }
    .buttonback{
        background-color: #fff;
        color: #735ae5;
        width: 60px;
        border-radius: 6px;
        font-family: "SFProDisplay-Bold;";
        font-weight: 900;
        padding: 0;
        cursor: pointer;
        height: 30px;
        border: solid 1px #927bfc!important;
        text-align: center;
        box-sizing : content-box;
        margin-right: 1.33rem;
        
        
        
    }
    .formrowbackground{
        margin-bottom: 20px;
        display:flex;
        align-items: center;
        text-align: center;
        background-color: #e0fcff;
        
    }
    .product{
        color:black;
        font-size: 20px;
        margin-top: 10px;
    }
    

`